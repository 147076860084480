import { HrefOrExplicitRequest } from "../components/core/DataGrid/ReadonlyDataGrid"
import { idOrEmptyRoute, toISOStringWIthTimezone } from "../utils/utils"
import { ActivityCategoryView } from "./ActivityCategory"
import { ApiClient, BlobResponse } from "./ApiClient"
import { IdDisplayPair } from "./Common"
import { ContactInformation, EMPTY_CONTACT_INFORMATION } from "./ContactInformation"
import { ApiDescription, EMPTY_API_DESCRIPTION, FilterWithValue, Paginated, PaginationRequest, SortCriterionWithValue, combineQueryParams, fakePaginated, getApiDescription, getHref, getPaginated, getResource, mapFiltersToQueryParamsObject, mapSortingToQueryParamObject, postResource, putResource } from "./Search"
import { ExportExcelTimeout, SampleStatusId } from "./WellKnowIds"

export type SampleListingViewModel =
    {
        sampleId: number
        sampleCode: number
        sampleCodeLegacy?: string
        sampleType: string
        sampleStatus: string
        sampleKind: string
        isRisk?: boolean
        sampleAnalysisLab?: string
        sampleActivityUnits: SampleActivityUnitModel[]
        activityCategory?: string
        firstName?: string
        lastName?: string
        inspector: string
        businessTitle?: string
        taxRegistrationNumber: string
        customerId: number
        date: Date
        sampleResult?: boolean
        isCounterSample: boolean
    }

export type SampleInitialBindingModel =
    {
        customerId: number
        inspectorId: number
        date: Date
        sampleTypeId: number
        activityCategoryId?: number
        basicActivityGroupId?: number
        sampleActivityUnits: SampleActivityUnitModel[]
        sampleKindId: number
        isRisk?: boolean
        inspectionId?: number
        note?: string
    }

export type SampleActivityUnitModel =
    {
        activityUnitId: number
        regionUnitName?: string
        municipalityName?: string
        municipalityUnitName?: string
        communityName?: string
        toponym?: string
        unitIdentifier?: string
    }

export type SampleDetailsViewModel = SampleInitialBindingModel &
{
    sampleId?: number
    sampleCode: number
    sampleCodeLegacy?: string
    resultsDate?: Date
    sampleSentDate?: Date
    file?: File
    activityCategory?: string
    basicActivityGroup?: string
    sampleStatus: string
    sampleStatusId: SampleStatusId
    voucher?: string
    businessTitle?: string
    firstName?: string
    lastName?: string
    sampleAnalysisLabId?: number
    activeSubstances: { activeSubstanceId: number, name: string, mlPerKg?: number }[]
    sampleResult?: boolean
    previousSampleKindName?: string
    originalSampleId?: number
    counterSampleId?: number
}

export const EMPTY_SAMPLE_INITIAL: SampleInitialBindingModel =
{
    customerId: 0,
    inspectorId: 0,
    date: new Date(),
    sampleTypeId: 0,
    sampleKindId: 0,
    sampleActivityUnits: []
}

export const EMPTY_SAMPLE_DETAILS: SampleDetailsViewModel =
{
    sampleCode: 0,
    customerId: 0,
    inspectorId: 0,
    sampleStatus: '',
    sampleStatusId: 0 as any,
    date: null as unknown as Date,
    sampleTypeId: 0,
    sampleKindId: 0,
    activeSubstances: [],
    sampleActivityUnits: [],
}

export type SampleKind =
    {
        sampleKindId: number
        kindName: string
        kind: string
        kindEn?: string
        kindBg?: string
        isDeleted: boolean
        omitActivityCategoryId?: boolean
    }

export type SampleKindBinding =
    {
        sampleKindId?: number
        kind: string
        kindEn?: string
        kindBg?: string
        omitActivityCategoryId?: boolean
    }

export type SampleType =
    {
        sampleTypeId: number
        type: string
    }

export type SampleAnalysisLabViewModel =
    {
        sampleAnalysisLabId: number
    } & UpsertSampleAnalysisLabModel

export type UpsertSampleAnalysisLabModel =
    {
        name: string
        addressFull: string
        contactInformation: ContactInformation
        note?: string
    }

export const EMPTY_SAMPLE_ANALYSIS_LAB = { name: "", addressFull: "", contactInformation: EMPTY_CONTACT_INFORMATION }

export type ActiveSubstance =
    {
        activeSubstanceId: number
        name: string
        substanceCategory?: string
    }

export type UpsertActiveSubstanceModel =
    {
        name: string
        substanceCategory?: string
    }

export type BatchImportSamplesResponse =
    {
        samples: SampleLine[]
        errors: string[]
        warnings: string[]
        connections?: InspectionConnection[]
    }

export type SampleLine = {
    lineNumber: number,
    taxRegistrationNumber: string,
    sampleDate: Date,
    inspectorLastName: string,
    inspectorFirstName: string,
    type: string,
    isRisk: boolean,
    sampleKind: string,
    connectWithInspection: boolean,
    notes?: string
}

export type InspectionConnection = {
    lineNumber: number,
    info: string,
}

export type SamplingActivityCategoryViewModel = {
    isProduct: boolean
    isStoredItem: boolean
    isFragment: boolean
} & ActivityCategoryView

export class SamplesApi {
    constructor(private readonly api: ApiClient) { }

    public get = (pagination: PaginationRequest, filters: FilterWithValue[], sorting: SortCriterionWithValue[]) => {
        const queryParams = combineQueryParams(pagination, mapFiltersToQueryParamsObject(filters), mapSortingToQueryParamObject(sorting))
        return this.getFromHref(`api/tenants/${this.api.tenantId}/samples?${queryParams.toString()}`)
    }

    public getFromHref = (href: string) =>
        this.api.onUnexpected(
            this.api.execute<Paginated<SampleListingViewModel>>(200, 'GET', href),
            "Could not load samples", fakePaginated([] as SampleListingViewModel[]))

    public describe = () =>
        this.api.onUnexpected(
            this.api.execute<ApiDescription>(200, 'OPTIONS', `api/tenants/${this.api.tenantId}/samples`),
            "Could not load api description", EMPTY_API_DESCRIPTION)

    public setSampleTaken = (sampleId: number, activityCategoryId: number, dryRun: boolean, date?: Date, sampleActivityUnits?: SampleActivityUnitModel[], sampleKindId?: number, note?: string, basicActivityGroupId?: number) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'POST', `api/tenants/${this.api.tenantId}/samples/${sampleId}/status/sample-taken?dryRun=${dryRun}`, undefined, { sampleActivityUnits, date, sampleKindId, note, activityCategoryId, basicActivityGroupId }),
            "Could not update sample", null, false, false, true)

    public setSampleNotTaken = (sampleId: number, note: string) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'POST', `api/tenants/${this.api.tenantId}/samples/${sampleId}/status/sample-not-taken`, undefined, { note: note, }),
            "Could not update sample", null)

    public setSampleCancelled = (sampleId: number, note: string) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'POST', `api/tenants/${this.api.tenantId}/samples/${sampleId}/status/sample-cancelled`, undefined, { note: note, }),
            "Could not update sample", null)

    public deleteSample = (sampleId: number) =>
        this.api.onUnexpected(
            this.api.execute<void>(204, 'DELETE', `api/tenants/${this.api.tenantId}/samples/${sampleId}`),
            "Could not delete sample", null)

    public getSamplingActivityCategories = (sampleId: number | undefined, customerId: number, basicActivityGroupId: number) =>
        this.api.onUnexpected(
            this.api.execute<SamplingActivityCategoryViewModel[]>(200, 'GET', `api/tenants/${this.api.tenantId}/samples/activity-categories`, { sampleId, customerId, basicActivityGroupId }),
            "Could not get sampling activity categories", [] as SamplingActivityCategoryViewModel[])

    public assignLab = (sampleId: number, sampleAnalysisLabId: number, isLegacy: boolean, sampleCodeLegacy?: string, note?: string) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'POST', `api/tenants/${this.api.tenantId}/samples/${sampleId}/status/assign-lab`, undefined,
                { sampleAnalysisLabId: sampleAnalysisLabId, isLegacy: isLegacy, sampleCodeLegacy: sampleCodeLegacy, note: note }),
            "Could not assign lab", null)

    public updateVoucher = (sampleId: number, voucher: string, sampleSentDate: Date, note: string | undefined, file: File) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('voucher', voucher)
        formData.append('sampleSentDate', toISOStringWIthTimezone(sampleSentDate))
        if (note)
            formData.append('note', note)

        return postResource(this.api, `api/tenants/${this.api.tenantId}/samples/${sampleId}/status/update-voucher`, formData, { timeout: this.api.maxTimeoutMs })
    }

    public updateResults = (sampleId: number, sampleResult: boolean, resultsDate: Date, substances: { activeSubstanceId: number, mlPerKg?: number }[], note?: string) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'POST', `api/tenants/${this.api.tenantId}/samples/${sampleId}/status/update-results`, undefined, { activeSubstances: substances, note: note, sampleResult: sampleResult, resultsDate: resultsDate }),
            "Could not update results", null)

    public getDetails = (sampleId: number) =>
        this.api.onUnexpected(
            this.api.execute<SampleDetailsViewModel>(200, 'GET', `api/tenants/${this.api.tenantId}/samples/${sampleId}`),
            "Could not load sample", EMPTY_SAMPLE_DETAILS)

    public getSampleKinds = (includeArchived?: boolean) =>
        this.api.onUnexpected(
            this.api.execute<SampleKind[]>(200, 'GET', `api/tenants/${this.api.tenantId}/samples/kinds`, { includeArchived }),
            "Could not load sample kinds", [] as SampleKind[])

    public archiveSampleKind = (kindId: number) =>
        this.api.onUnexpected(
            this.api.execute<null>(204, 'DELETE', `api/tenants/${this.api.tenantId}/samples/kinds/${kindId}`),
            "Could not archive sample kind", undefined)

    public upsertSampleKind = (kindId: number | undefined, kind: string, omitActivityCategoryId: boolean | undefined, kindEn?: string, kindBg?: string) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'PUT', `api/tenants/${this.api.tenantId}/samples/kinds${idOrEmptyRoute(kindId)}`, undefined, { kind: kind, omitActivityCategoryId, kindEn: kindEn, kindBg: kindBg }),
            "Could not upsert sample kind", undefined)

    public getSampleTypes = () =>
        this.api.onUnexpected(
            this.api.execute<SampleType[]>(200, 'GET', `api/tenants/${this.api.tenantId}/samples/types`),
            "Could not load sample kinds", [] as SampleType[])

    public getSampleLab = (id: number) =>
        getResource<SampleAnalysisLabViewModel>(this.api, `api/tenants/${this.api.tenantId}/samples/labs/${id}`, { sampleAnalysisLabId: 0, ...EMPTY_SAMPLE_ANALYSIS_LAB })

    public upsertSampleAnalysisLab = (id: number | undefined, model: UpsertSampleAnalysisLabModel) =>
        putResource(this.api, `api/tenants/${this.api.tenantId}/samples/labs${idOrEmptyRoute(id)}`, model)

    public getSampleLabs = (request: HrefOrExplicitRequest) =>
        getPaginated<SampleAnalysisLabViewModel>(this.api, getHref(request, `api/tenants/${this.api.tenantId}/samples/labs`))

    public getSampleLabsApi = () =>
        getApiDescription(this.api, `api/tenants/${this.api.tenantId}/samples/labs`)

    public getActiveSubstances = (request: HrefOrExplicitRequest) =>
        getPaginated<ActiveSubstance>(this.api, getHref(request, `api/tenants/${this.api.tenantId}/samples/active-substances`))

    public getActiveSubstancesApi = () =>
        getApiDescription(this.api, `api/tenants/${this.api.tenantId}/samples/active-substances`)

    public upsertActiveSubstance = (id: number | undefined, model: UpsertActiveSubstanceModel) =>
        putResource(this.api, `api/tenants/${this.api.tenantId}/samples/active-substances${idOrEmptyRoute(id)}`, model)

    public getStatuses = () =>
        this.api.onUnexpected(
            this.api.execute<IdDisplayPair[]>(200, 'GET', `api/tenants/${this.api.tenantId}/samples/statuses`),
            "Δεν είναι δυνατή η εμφάνιση των καταστάσεων δείγματος", [] as IdDisplayPair[])

    public upsertSample = (sampleId: number | undefined, sample: SampleInitialBindingModel) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'PUT', `api/tenants/${this.api.tenantId}/samples${idOrEmptyRoute(sampleId)}`, undefined, sample),
            "Could not add sample", null)

    public changeStatusNoCheck = (sampleId: number, statusId: SampleStatusId) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'POST', `api/tenants/${this.api.tenantId}/samples/${sampleId}/status-no-check`, undefined, { sampleStatusId: statusId }),
            "Δεν είναι δυνατή η μεταβολή δείγματος", null)

    public getExcel = (filters: FilterWithValue[], sorting: SortCriterionWithValue[]) => {
        const queryParams = combineQueryParams(mapFiltersToQueryParamsObject(filters), mapSortingToQueryParamObject(sorting))
        return this.api.onUnexpected(
            this.api.execute<BlobResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/samples/export`, queryParams, undefined, ExportExcelTimeout, undefined, undefined, true),
            "Could not export given search to Excel",
            null)
    }

    public batchImportSamples = async (model: { file: File, isDryRun: boolean, sheetName: string }): Promise<BatchImportSamplesResponse | {} | null> => {
        const formData = new FormData()
        formData.append('file', model.file)
        formData.append('isDryRun', model.isDryRun.toString())
        formData.append('sheetName', model.sheetName)

        const response = await this.api.execute<BatchImportSamplesResponse>(204, 'POST', `api/tenants/${this.api.tenantId}/samples/batch`, undefined, formData, 12 * 60000)
        switch (response.kind) {
            case 'success': return {}
            case 'unexpected':
                if (response.status === 200 || response.status === 400)
                    return response.data as BatchImportSamplesResponse
                else
                    return await this.api.onUnexpected(Promise.resolve(response), "Could not batch import samples", null)
        }
    }

    public createCounterSample = (sampleId: number) =>
        putResource<null, { sampleId: number }>(this.api, `api/tenants/${this.api.tenantId}/samples/counter-samples/${sampleId}`, null)
}