import { AddressLikeBindingModel, AddressLikeFullModel, EMPTY_ADDRESS } from "./Address"
import { ApiClient, BlobResponse } from "./ApiClient"
import { CertificationOrganizationViewModel } from "./CertificationOrganizations"
import { ContactInformation } from "./ContactInformation"
import { ApiDescription, combineQueryParams, EMPTY_API_DESCRIPTION, fakePaginated, FilterWithValue, mapFiltersToQueryParamsObject, mapSortingToQueryParamObject, Paginated, PaginationRequest, SortCriterionWithValue } from "./Search"
import { ExportExcelTimeout } from "./WellKnowIds"

export type SubcontractorRelationshipListingViewModel =
    {
        entityRelationshipId: number
        taxRegistrationNumber: string
        distinctiveTitle: string
        firstName: string
        lastName: string
        middleName?: string
        note?: string
        subcontractorType: string
        subcontractorTypeCode?: string
        basicActivityGroupName: string
        certificationOrganizationName?: string
        externalDestId?: number
        applicationDestId?: number
        customerDestId?: number
        sourceCustomerId?: number
        deletionAllowed: boolean
    }

type SubcontractorModelBase<Addr> =
    {
        externalSubcontractorId?: number
        taxRegistrationNumber?: string
        distinctiveTitle: string
        firstName: string
        lastName: string
        middleName?: string
        address: Addr
        contactInformation: ContactInformation
        certificationOrganizationId?: number
        certificationOrganization?: CertificationOrganizationViewModel
        note?: string
    }

export type SubcontractorBindingModel = SubcontractorModelBase<AddressLikeBindingModel>

export type SubcontractorViewModel = SubcontractorModelBase<AddressLikeFullModel>

export const EMPTY_SUBCONTRACTOR: SubcontractorBindingModel =
{
    distinctiveTitle: '',
    firstName: '',
    lastName: '',
    address: EMPTY_ADDRESS,
    contactInformation: { email: [], faxNumber: [], landlineNumber: [], mobileNumber: [], website: [] }
}

export type SubcontractorAdded =
    {
        externalSubcontractorId?: number
    }

export class SubcontractorsApi {
    constructor(private readonly api: ApiClient) { }

    public upsertSubcontractor = (c: SubcontractorBindingModel) =>
        this.api.onUnexpected(
            this.api.execute<SubcontractorAdded>(200, 'PUT', `api/tenants/${this.api.tenantId}/external-subcontractors${c.externalSubcontractorId ? `/${c.externalSubcontractorId}` : ''}`, undefined, c)
            , "Could not add subcontractor", null)

    public getDetails = (externalSubcontractorId: number) =>
        this.api.onUnexpected(
            this.api.execute<SubcontractorViewModel>(200, 'GET', `api/tenants/${this.api.tenantId}/external-subcontractors/${externalSubcontractorId}`)
            , "Could not load subcontractor", null)

    public get = (pagination: PaginationRequest, filters: FilterWithValue[], sorting: SortCriterionWithValue[]) => {
        const queryParams = combineQueryParams(pagination, mapFiltersToQueryParamsObject(filters), mapSortingToQueryParamObject(sorting))
        return this.getFromHref(`api/tenants/${this.api.tenantId}/external-subcontractors?${queryParams.toString()}`);
    }

    public getFromHref = (href: string) =>
        this.api.onUnexpected(
            this.api.execute<Paginated<SubcontractorViewModel>>(200, 'GET', href)
            , "Could not load subcontractors", fakePaginated([] as SubcontractorViewModel[]))

    public describe = () =>
        this.api.onUnexpected(
            this.api.execute<ApiDescription>(200, 'OPTIONS', `api/tenants/${this.api.tenantId}/external-subcontractors`)
            , "Could not load api description", EMPTY_API_DESCRIPTION)

    public delete = (externalSubcontractorId: number) =>
        this.api.onUnexpected(
            this.api.execute<{}>(204, 'DELETE', `api/tenants/${this.api.tenantId}/external-subcontractors/${externalSubcontractorId}`)
            , "Could not delete subcontractor, there are customer relationships", null)

    public getExcel = (filters: FilterWithValue[], sorting: SortCriterionWithValue[]) => {
        const queryParams = combineQueryParams(mapFiltersToQueryParamsObject(filters), mapSortingToQueryParamObject(sorting))
        return this.api.onUnexpected(
            this.api.execute<BlobResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/external-subcontractors/export`, queryParams, undefined, ExportExcelTimeout, undefined, undefined, true),
            "Could not export given search to Excel",
            null)
    }
}