import { HrefOrExplicitRequest } from "../components/core/DataGrid/ReadonlyDataGrid"
import { TimeOnly } from "../utils/timeOnly"
import { idOrEmptyRoute } from "../utils/utils"
import { AddressLikeFullModel, CustomerAddress, EMPTY_ADDRESS } from "./Address"
import { ApiClient, BlobResponse } from "./ApiClient"
import { CertificationStateView } from "./CertificationStandards"
import { IdDisplayPair, IdResult } from "./Common"
import { ContactInformation } from "./ContactInformation"
import { SampleActivityUnitModel } from "./Sample"
import { ApiDescription, EMPTY_API_DESCRIPTION, FilterWithValue, Paginated, PaginationRequest, SortCriterionWithValue, combineQueryParams, deleteResource, fakePaginated, getApiDescription, getExport, getHref, getPaginated, getResource, mapFiltersToQueryParamsObject, mapSortingToQueryParamObject, postResource, putResource } from "./Search"
import { ActivityUnitSpecificKindId, BasicActivityGroupId, ExportExcelTimeout, InspectionStatusId, InspectionVersion, LegalTypeId } from "./WellKnowIds"

export type InspectionActivationResult = { isDelinquent: boolean }
export type InspectionActivationModel = {
    effectiveDate: Date,
    startTime?: TimeOnly,
    endTime?: TimeOnly,
    inspectionGroupIds?: number[]
}

export type InspectionListingViewModel =
    {
        inspectionId: number
        customerId: number
        distinctiveTitle: string
        inspectorFirstName?: string
        inspectorLastName?: string
        initialDate: Date
        plannedDate?: Date
        effectiveDate?: Date
        status: string
        inspectionStatusId: InspectionStatusId
        type: string
        isUnannounced: boolean
        isPartial: boolean
        isRisk?: boolean
        certificationStandards: string[]
        basicActivityGroups: string[]
        hasSamples: boolean
        customerName: string
        scheduledAsNewCustomer?: boolean
        scheduledAsOrganizationTransfer?: boolean
        scheduledAsActivityUnitTransfer?: boolean
        scheduledAsSharedUnits?: boolean
        scheduledAsRotation?: boolean
        createdByAnnualScheduler?: boolean
    }

export type InspectionSamplesListingViewModel =
    {
        sampleId: number
        sampleCode: number
        sampleType: string
        sampleStatus: string
        sampleKind: string
        sampleAnalysisLab?: string
        sampleActivityUnits: SampleActivityUnitModel[]
        activityCategory: string
        firstName?: string
        lastName?: string
        businessTitle?: string
        taxRegistrationNumber?: string
        sampleResult?: boolean
    }

export type InspectionViewModel =
    {
        inspectionId: number
        customerId: number
        inspectorId?: number
        initialDate: Date
        plannedDate?: Date
        effectiveDate?: Date
        startTime?: TimeOnly,
        endTime?: TimeOnly,
        inspectionStatus: string
        inspectionStatusId: InspectionStatusId
        inspectionTypeId: number
        isUnannounced: boolean
        isRisk?: boolean
        isPartial: boolean
        restrictions?: {
            regionUnits: number[]
        },
        applicationIds: number[]
        certificationStandards: number[]
        rejectionReasons?: string
        cancellationReasons?: string
        note?: string
        inspectorNote?: string
        scheduledAsNewCustomer?: boolean
        scheduledAsOrganizationTransfer?: boolean
        scheduledAsActivityUnitTransfer?: boolean
        scheduledAsSharedUnits?: boolean
        scheduledAsRotation?: boolean
        version: InspectionVersion
        inspectionGroupIds: number[]
    } & InspectionExtraInfo

export type InspectionExtraInfo =
    {
        hasNonCompliance?: boolean
        isSelling?: boolean
        hasDocumentRequest?: boolean
    }

export type InspectionBindingModel =
    {
        inspectorId?: number
        inspectionTypeId: number
        isUnannounced: boolean
    }

export const EMPTY_INSPECTION_VIEWMODEL: InspectionViewModel =
{
    inspectionId: 0,
    customerId: 0,
    initialDate: new Date(),
    inspectionStatus: '',
    inspectionStatusId: InspectionStatusId.Draft,
    inspectionTypeId: 0,
    certificationStandards: [],
    applicationIds: [],
    isUnannounced: false,
    isPartial: false,
    version: InspectionVersion.V1,
    inspectionGroupIds: []
}

export type InspectionPaymentListingViewModel =
    {
        inspectionId: number
        customerId: number
        inspector: string
        effectiveInspectionCost: number
        calculatedAmount: number
        primaryReductionPercentage?: number
        secondaryReductionPercentage?: number
        isLocked: boolean
        needsAttention: boolean
        activityGroups: string[]
        inspectionType: string
        inspectionDate?: Date
        modifiedUser: string
        dateModified: Date
    }

export type InspectionPaymentBindingModel =
    {
        overrideInspectionCost?: number
        secondaryReductionPercentage?: number
    }

export type InspectionPaymentViewModel =
    {
        inspectionId: number
        effectiveInspectionCost: number
        overrideInspectionCost?: number
        calculatedAmount: number
        primaryReductionPercentage?: number
        secondaryReductionPercentage?: number
    }

export type ProductQuantitiesBindingModel =
    {
        certificationStandardId: number
        activityCategoryId: number
        quantity?: number
        organicCertificationCategoryId: number
        byProductId: number
    }

export type ProductQuantitiesViewModel =
    ProductQuantitiesBindingModel & {
        certificationStandard: string
        name: string
        organicCertificationCategory: string
        canOmitQuantity: boolean
        byProduct: string
        isDefaultByProduct: boolean
    }

export type InspectionActivityUnitView = {
    customerActivityUnitId?: number
    applicationActivityUnitId?: number
    persistentActivityUnitId: number
    communityName: string
    regionUnitName: string
    postalCode?: string
    units?: number
    squareMeters?: number
    areaStremmata?: number
    activityUnitSpecificKindId?: ActivityUnitSpecificKindId
    weightKg?: number
    cbm?: number
    addressLine1?: string
    toponym?: string
    unitIdentifier?: string
    owned: boolean
    fragments: InspectionActivityUnitFragmentViewModel[]
    isPropagatingMaterial?: boolean
    unitCertificationStandards: CertificationStateView[]
    basicActivityGroupId: number
    checked: boolean
}

export type InspectionActivityUnitFragmentViewModel =
    {
        name: string
        units?: number
        squareMeters?: number
        areaStremmata?: number
        code?: string
        products: string[]
    }

export type InspectionWarehouseView = {
    customerWarehouseId?: number
    applicationWarehouseId?: number
    communityName: string
    regionUnitName: string
    toponym: string
    unitIdentifier?: string
    storedItems: string[]
    squareMeters?: number
    owned: boolean
    basicActivityGroupId: number
    checked: boolean
}

export type InspectionPropertyView = {
    customerPropertyId?: number
    applicationPropertyId?: number
    basicActivityGroupId: number
    checked: boolean
} & AddressLikeFullModel

export type InspectionGroupViewModel = {
    basicGroup: string
    basicActivityGroupId: number
    activityUnitViews: InspectionActivityUnitView[]
    warehouseViews: InspectionWarehouseView[]
    propertyViews: InspectionPropertyView[]
}

export type CustomerInfoView = {
    applicationId?: number
    customerId?: number
    checked: boolean

    legalTypeId?: LegalTypeId
    distinctiveTitle: string
    taxRegistrationNumber: string
    address?: Partial<CustomerAddress>
    contactInformation?: Partial<ContactInformation>

    firstName?: string
    lastName?: string
    middleName?: string
    nationalIdentityNumber: string
    businessTitle?: string
}

export const EMPTY_CUSTOMER_INFO_VIEW: CustomerInfoView =
{
    distinctiveTitle: '',
    taxRegistrationNumber: '',
    contactInformation: {},
    nationalIdentityNumber: '',
    address: EMPTY_ADDRESS,
    checked: false
}

export type CustomerViewModel = {
    groupViewModels: InspectionGroupViewModel[]
    customerInfoView: CustomerInfoView
    allChecked: boolean
}

export type InspectionCheckedEntities = {
    applicationId?: number
    customerId?: number
    persistentActivityUnitIds: number[]
    applicationWarehouseIds: number[]
    customerWarehouseIds: number[]
    applicationPropertyIds: number[]
    customerPropertyIds: number[]
}

export type ProductQuantitiesGroups = {
    canBypassQuantities: boolean,
    groups: { basicActivityGroupId: BasicActivityGroupId, basicActivityGroup: string, quantities: ProductQuantitiesViewModel[] }[]
}

export type BatchImportInspectionsRecordsResponse =
    {
        inspections: InspectionLine[]
        errors: string[]
        warnings: string[]
    }

export type InspectionLine = {
    taxRegistrationNumber: string,
    inspectionDate: Date,
    inspectorLastName: string,
    inspectorFirstName: string,
    isUnannounced: boolean,
    isRisk: boolean,
    type: string,
    inspectionCertificationStandard: string
}

export type InspectionReimbursementBindingModel = {
    inspectionReimbursementId?: number
    date: Date
    amount?: number
    description?: string
    distanceInKm?: number
    amountDueToKmDistance?: number
    isLocked: boolean
    inspectionIds: number[]
    isInspectorUser: boolean
}

export type InspectionReimbursementViewModel = InspectionReimbursementBindingModel & {
    inspector: string
    inspectorId: number
    modifiedUser: string
    dateModified: Date
}

export type InspectionHistoryRecord =
    {
        initialDate?: Date
        plannedDate?: Date
        effectiveDate?: Date
        dateCreated: Date
        inspector?: string
        inspectionStatus?: string
        rejectionReason?: string
        createdUser: string
    }

export type InspectionRejectionReason =
    {
        name: string
        inspectionRejectionReasonId: number
        requiresDocument: boolean
    }

export type InspectionGroupsSuggestions =
    {
        groupIds: BasicActivityGroupId[],
        hasProcessingUnit: boolean
        hasUnaffiliatedSubcontractor: boolean
        hasSelfProducedProcessingUnit: boolean
    }

export class InspectionsApi {
    constructor(private readonly api: ApiClient) { }

    public get = (pagination: PaginationRequest, filters: FilterWithValue[], sorting: SortCriterionWithValue[]) => {
        const queryParams = combineQueryParams(pagination, mapFiltersToQueryParamsObject(filters), mapSortingToQueryParamObject(sorting))
        return this.getFromHref(`api/tenants/${this.api.tenantId}/inspections?${queryParams.toString()}`)
    }

    public getFromHref = (href: string) =>
        this.api.onUnexpected(
            this.api.execute<Paginated<InspectionListingViewModel>>(200, 'GET', href),
            "Δεν είναι δυνατή η εμφάνιση των επιθεώρησεων", fakePaginated([] as InspectionListingViewModel[]))

    public describe = () =>
        this.api.onUnexpected(
            this.api.execute<ApiDescription>(200, 'OPTIONS', `api/tenants/${this.api.tenantId}/inspections`),
            "Could not load api description", EMPTY_API_DESCRIPTION)

    public getDetails = (inspectionId: number) =>
        this.api.onUnexpected(
            this.api.execute<InspectionViewModel>(200, 'GET', `api/tenants/${this.api.tenantId}/inspections/${inspectionId}`),
            "Δεν είναι δυνατή η εμφάνιση της επιθεώρησης", EMPTY_INSPECTION_VIEWMODEL)

    public getInspectionSamples = (inspectionId: number) =>
        this.api.onUnexpected
            (this.api.execute<InspectionSamplesListingViewModel[]>(200, 'GET', `api/tenants/${this.api.tenantId}/inspections/${inspectionId}/samples`),
                "Δεν φορτώθηκαν τα δείγματα της επιθεώρησης", [] as InspectionSamplesListingViewModel[])

    public upsertMonthlySchedule = (month: number, year: number, inspectionIds: number[]) =>
        putResource(this.api, `api/tenants/${this.api.tenantId}/inspections/monthly-schedule`, { month, year, inspectionIds }, { expected: 204, timeout: this.api.maxTimeoutMs })

    public upsert = (inspectionId: number | undefined, c: InspectionBindingModel) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'PUT', `api/tenants/${this.api.tenantId}/inspections${inspectionId ? `/${inspectionId}` : ''}`, undefined, c),
            "Δεν είναι δυνατή η μεταβολή της επιθεώρησης", null)

    public upsertDate = (inspectionId: number, date: Date) =>
        this.api.onUnexpected(
            this.api.execute<InspectionActivationResult>(200, 'PUT', `api/tenants/${this.api.tenantId}/inspections/${inspectionId}/date-correction`, undefined, { date: date }),
            "Δεν είναι δυνατή η μεταβολή της ημερομηνίας της επιθεώρησης", null)

    public upsertExtraDetails = (inspectionId: number, extraInfo: InspectionExtraInfo) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'PUT', `api/tenants/${this.api.tenantId}/inspections/${inspectionId}/extra-details`, undefined, extraInfo),
            "Δεν είναι δυνατή η μεταβολή των πληροφοριών της επιθεώρησης", null)

    public batchSet = (inspectionIds: number[] | null, filters: FilterWithValue[] | null, state: { inspectionStatusId?: InspectionStatusId, inspectorId?: number }) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'POST', `api/tenants/${this.api.tenantId}/inspections/batch`, mapFiltersToQueryParamsObject(filters ?? []), { inspectionIds: inspectionIds, ...state }),
            "Δεν είναι δυνατή η μεταβολή των επιθεώρησεων", null)

    public batchDelete = (inspectionIds: number[] | null, filters: FilterWithValue[] | null) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'DELETE', `api/tenants/${this.api.tenantId}/inspections/batch`, mapFiltersToQueryParamsObject(filters ?? []), { inspectionIds: inspectionIds }),
            "Δεν είναι δυνατή η μεταβολή των επιθεώρησεων", null)

    public setStatus = (inspectionId: number, status?: InspectionStatusId, date?: Date, effectiveDate?: Date, extraInfo?: InspectionExtraInfo, noCheck?: boolean, inspectorNote?: string) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'POST', `api/tenants/${this.api.tenantId}/inspections/${inspectionId}/status`, undefined, { inspectionStatusId: status, plannedDate: date, effectiveDate: effectiveDate, noCheck: noCheck, inspectorNote: inspectorNote, ...extraInfo }),
            "Δεν είναι δυνατή η μεταβολή της επιθεώρησης", null)

    public calculatePayment = (inspectionId: number) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'POST', `api/tenants/${this.api.tenantId}/inspections/${inspectionId}/payment`),
            "Could not calculate payment", null)

    public getPayment = (inspectionId: number) =>
        getResource<InspectionPaymentViewModel>(this.api, `api/tenants/${this.api.tenantId}/inspections/${inspectionId}/payment`, {} as InspectionPaymentViewModel)

    public upsertPayment = (inspectionId: number, model: InspectionPaymentBindingModel) =>
        putResource(this.api, `api/tenants/${this.api.tenantId}/inspections/${inspectionId}/payment`, model)

    public lockPayments = (inspectionIds: number[] | null, filters: FilterWithValue[] | null, toLock: boolean) =>
        this.api.onUnexpected(
            this.api.execute<{ count: number }>(200, 'PUT', `api/tenants/${this.api.tenantId}/inspections/payments/lock`, mapFiltersToQueryParamsObject(filters ?? []), { inspectionIds, toLock }),
            "Could not lock payments", null)

    public getPayments = (request: HrefOrExplicitRequest) =>
        getPaginated<InspectionPaymentListingViewModel>(this.api, getHref(request, `api/tenants/${this.api.tenantId}/inspections/payments`))

    public describePaymentsApi = () =>
        getApiDescription(this.api, `api/tenants/${this.api.tenantId}/inspections/payments`)

    public getPaymentsExcel = (filters: FilterWithValue[], sorting: SortCriterionWithValue[]) =>
        getExport(this.api, `api/tenants/${this.api.tenantId}/inspections/payments/export`, filters, sorting)

    public changeStatusNoCheck = (inspectionId: number, status: InspectionStatusId) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'POST', `api/tenants/${this.api.tenantId}/inspections/${inspectionId}/status-no-check`, undefined, { inspectionStatusId: status }),
            "Δεν είναι δυνατή η μεταβολή της επιθεώρησης", null)

    public getStatuses = () =>
        this.api.onUnexpected(
            this.api.execute<IdDisplayPair[]>(200, 'GET', `api/tenants/${this.api.tenantId}/inspections/statuses`),
            "Δεν είναι δυνατή η εμφάνιση των καταστάσεων επιθεώρησης", [] as IdDisplayPair[])

    public getTypes = () =>
        this.api.onUnexpected(
            this.api.execute<IdDisplayPair[]>(200, 'GET', `api/tenants/${this.api.tenantId}/inspections/types`),
            "Δεν είναι δυνατή η εμφάνιση των τύπων επιθεώρησης", [] as IdDisplayPair[])

    public getExcel = (filters: FilterWithValue[], sorting: SortCriterionWithValue[], emitExtras: boolean, emitCustomerDetails: boolean) => {
        const queryParams = combineQueryParams({ emitExtras, emitCustomerDetails }, mapFiltersToQueryParamsObject(filters), mapSortingToQueryParamObject(sorting))
        return this.api.onUnexpected(
            this.api.execute<BlobResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/inspections/export`, queryParams, undefined, ExportExcelTimeout, undefined, undefined, true),
            "Δεν είναι δυνατή η εμφάνιση των επιθεώρησεων σε excel",
            null)
    }

    public getProductQuantities = (inspectionId: number) =>
        this.api.onUnexpected(
            this.api.execute<ProductQuantitiesGroups>(200, 'GET', `api/tenants/${this.api.tenantId}/inspections/${inspectionId}/product-quantities`),
            "Δεν είναι δυνατή η εμφάνιση των ποσοτήτων επιθεώρησης", { canBypassQuantities: false, groups: [] } as ProductQuantitiesGroups)

    public upsertProductQuantities = (inspectionId: number, quantities: ProductQuantitiesBindingModel[]) =>
        this.api.onUnexpected(
            this.api.execute<{ quantities: ProductQuantitiesBindingModel[] }>(200, 'PUT', `api/tenants/${this.api.tenantId}/inspections/${inspectionId}/product-quantities`, undefined, { quantities: quantities }),
            "Δεν είναι δυνατή η μεταβολή των ποσοτήτων επιθεώρησης", null)

    public getCustomerView = (inspectionId: number) =>
        this.api.onUnexpected(
            this.api.execute<CustomerViewModel>(200, 'GET', `api/tenants/${this.api.tenantId}/inspections/${inspectionId}/customer-view`),
            "Δεν είναι δυνατη η εμφάνισης της εικόνας του πελάτη", null)

    public getCustomerViewExcel = (inspectionId: number) => {
        return this.api.onUnexpected(
            this.api.execute<BlobResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/inspections/${inspectionId}/customer-view/export`, undefined, undefined, ExportExcelTimeout, undefined, undefined, true),
            "Δεν είναι δυνατή η εμφάνιση της εικόνας πελάτη σε excel",
            null)
    }

    public upsertCheckedEntities = (inspectionId: number, model: InspectionCheckedEntities) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'PUT', `api/tenants/${this.api.tenantId}/inspections/${inspectionId}/checked-entities`, undefined, model),
            "Δεν είναι δυνατή η επεξεργασία των ελεγμένων μονάδων", null)

    public batchImportInspections = async (model: { file: File, isDryRun: boolean, sheetName: string }): Promise<BatchImportInspectionsRecordsResponse | {} | null> => {
        const formData = new FormData()
        formData.append('file', model.file)
        formData.append('isDryRun', model.isDryRun.toString())
        formData.append('sheetName', model.sheetName)

        const response = await this.api.execute<BatchImportInspectionsRecordsResponse>(204, 'POST', `api/tenants/${this.api.tenantId}/inspections/batch-insert`, undefined, formData, 12 * 60000)
        switch (response.kind) {
            case 'success': return {}
            case 'unexpected':
                if (response.status === 200 || response.status === 400)
                    return response.data as BatchImportInspectionsRecordsResponse
                else
                    return await this.api.onUnexpected(Promise.resolve(response), "Could not batch import inspections records", null)
        }
    }

    public getCosts = (request: HrefOrExplicitRequest) =>
        getPaginated<InspectionReimbursementViewModel>(this.api, getHref(request, `api/tenants/${this.api.tenantId}/inspections/costs`))

    public describeCostsApi = () =>
        getApiDescription(this.api, `api/tenants/${this.api.tenantId}/inspections/costs`)

    public upsertInspectionCost = (inspectionReimbursementId: number | undefined, model: InspectionReimbursementBindingModel) =>
        putResource<InspectionReimbursementBindingModel, IdResult>(this.api, `api/tenants/${this.api.tenantId}/inspections/costs${idOrEmptyRoute(inspectionReimbursementId)}`, model)

    public getInspectionCost = (inspectionReimbursementId: number) =>
        this.api.onUnexpected(
            this.api.execute<InspectionReimbursementViewModel>(200, 'GET', `api/tenants/${this.api.tenantId}/inspections/costs/${inspectionReimbursementId}`),
            "Δεν είναι δυνατή η εμφάνιση του κόστους", null)

    public getReimbursementsExcel = (filters: FilterWithValue[], sorting: SortCriterionWithValue[]) =>
        getExport(this.api, `api/tenants/${this.api.tenantId}/inspections/reimbursements/export`, filters, sorting)

    public deleteReimbursement = (reimbursementId: number) =>
        deleteResource(this.api, `api/tenants/${this.api.tenantId}/inspections/reimbursements/${reimbursementId}`)

    public lockReimbursements = (inspectionReimbursementIds: number[] | null, filters: FilterWithValue[] | null) =>
        putResource<{}, { count: number }>(this.api, `api/tenants/${this.api.tenantId}/inspections/reimbursements/lock`, { inspectionReimbursementIds }, { queryParams: mapFiltersToQueryParamsObject(filters ?? []) })

    public getInspectionsPlanningReport = (year: number, month: number) =>
        getResource<BlobResponse | null>(this.api, `api/tenants/${this.api.tenantId}/inspections/reports/planning-per-month`, null, { blob: true, queryParams: { year, month } })

    public bulkClearEffectiveDates = (inspectionIds: number[] | null, filters: FilterWithValue[] | null) =>
        putResource<{ inspectionIds: number[] | null }, {}>(this.api, `api/tenants/${this.api.tenantId}/inspections/bulk/clear-effective-date`, { inspectionIds: inspectionIds }, { queryParams: mapFiltersToQueryParamsObject(filters ?? []) })

    public getInspectionsMonthlyReviewReport = (year: number, month: number) =>
        getResource<BlobResponse | null>(this.api, `api/tenants/${this.api.tenantId}/inspections/reports/review-per-month`, null, { blob: true, queryParams: { year, month } })

    public getInspectionsAnnualReviewReport = (year: number) =>
        getResource<BlobResponse | null>(this.api, `api/tenants/${this.api.tenantId}/inspections/reports/review-per-year`, null, { blob: true, queryParams: { year } })

    public getCustomerInspectionsAnnualReviewReport = (year: number, includeExtraInfo?: boolean) =>
        getResource<BlobResponse | null>(this.api, `api/tenants/${this.api.tenantId}/inspections/reports/customers-review-per-year`, null, { blob: true, queryParams: { year, includeExtraInfo } })

    public getSamplesSubstancesAuxReport = (year: number) =>
        getResource<BlobResponse | null>(this.api, `api/tenants/${this.api.tenantId}/inspections/reports/samples-substances-aux-per-year`, null, { blob: true, queryParams: { year } })

    public getInspectorsAnnualReport = (year: number) =>
        getResource<BlobResponse | null>(this.api, `api/tenants/${this.api.tenantId}/inspections/reports/inspectors-review-per-year`, null, { blob: true, queryParams: { year } })

    public getRejectionReasons = () =>
        getResource<InspectionRejectionReason[]>(this.api, `api/tenants/${this.api.tenantId}/inspections/rejection-reasons`, [] as InspectionRejectionReason[])

    public getInspectionHistoryRecords = (inspectionId: number) =>
        getResource<InspectionHistoryRecord[]>(this.api, `api/tenants/${this.api.tenantId}/inspections/${inspectionId}/history`, [] as InspectionHistoryRecord[])

    public reject = (inspectionId: number, rejectionReasonId: number, rejectionReasons?: string, file?: File) => {
        const formData = new FormData()
        formData.append('rejectionReasonId', rejectionReasonId.toString())
        if (file)
            formData.append('file', file)
        if (rejectionReasons)
            formData.append('rejectionReasons', rejectionReasons)
        return postResource(this.api, `api/tenants/${this.api.tenantId}/inspections/${inspectionId}/reject`, formData, { timeout: this.api.maxTimeoutMs })
    }

    public activate = (inspectionId: number, model: InspectionActivationModel) =>
        postResource<unknown, InspectionActivationResult>(this.api, `api/tenants/${this.api.tenantId}/inspections/${inspectionId}/activate`, model)

    public getCustomerPendingDocsForInspection = (inspectionId: number) =>
        getResource<CustomerPendingDocsForInspection | null>(this.api, `api/tenants/${this.api.tenantId}/inspections/${inspectionId}/pending-for-customer`, null)

    public getInspectionSuggestedGroups = (inspectionId: number) =>
        getResource<InspectionGroupsSuggestions | null>(this.api, `api/tenants/${this.api.tenantId}/inspections/${inspectionId}/suggested-customer-groups`, null)

    public getSupportedDigitalDocumentTypes = (inspectionId: number) =>
        getResource<{ supportedTypes: SupportedDigitalDocumentType[] } | null>(this.api, `api/tenants/${this.api.tenantId}/inspections/${inspectionId}/supported-digital-document-types`, null)

    public batchCreateDigitalDocuments = (inspectionId: number, documentTypeIds: number[]) =>
        postResource(this.api, `api/tenants/${this.api.tenantId}/inspections/${inspectionId}/batch-digital-documents`, { documentTypeIds })

    public completeInspection = (inspectionId: number, extraInfo?: InspectionExtraInfo, noCheck?: boolean) =>
        postResource(this.api, `api/tenants/${this.api.tenantId}/inspections/${inspectionId}/complete`, { ...extraInfo, noCheck })
}

export type CustomerPendingDocsForInspection =
    {
        hasAnyPending: boolean
        numberOfPendingDocs: number
        pendingNewCustomerAgreements: number
    }

export type SupportedDigitalDocumentType =
    {
        documentTypeId: number
        isSuggested: boolean
    }