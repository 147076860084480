import { darkScrollbar } from '@mui/material'
import { amber, blue, blueGrey, brown, green, grey, lime, yellow } from '@mui/material/colors'
import {
    DeprecatedThemeOptions,
    StyledEngineProvider,
    ThemeProvider,
    adaptV4Theme,
    createTheme,
} from '@mui/material/styles'
import _ from 'lodash'
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { ENVIRONMENT, Environment } from './ConfigurationInjection'
import { BasicActivityGroupId } from './api/WellKnowIds'
import { openApplicationIdb } from './components/core/LocalStore/IndexedDb'

const baseThemeOptions = (_th: DeprecatedThemeOptions): DeprecatedThemeOptions => ({
    props: {
        MuiTextField: {
            variant: 'standard',
        },
        MuiSelect: {
            variant: 'standard',
        },
        MuiFormControl: {
            variant: 'standard',
        },
        MuiButton: {
            color: 'inherit',
        },
        MuiLink: {
            underline: 'hover',
            fontStyle: 'oblique',
        },
    },
})

const appOptions = {
    groups: {
        palette: {
            crop: { main: green[800] },
            [BasicActivityGroupId.Crop]: { main: green[800] },

            livestock: { main: brown[700] },
            [BasicActivityGroupId.Livestock]: { main: brown[700] },

            processing: { main: grey[700] },
            [BasicActivityGroupId.Processing]: { main: grey[700] },

            beekeeping: { main: amber[600] },
            [BasicActivityGroupId.Beekeeping]: { main: amber[600] },

            aquaculture: { main: blue[900] },
            [BasicActivityGroupId.Aquaculture]: { main: blue[900] },
        },
    }
}

const themes = {
    'prod': {
        sampleColor: '#003300',
        options: {
            palette: {
                primary: { main: '#003300' },
                secondary: { main: '#bf360c' },
                background: {
                    default: '#fafafa',
                },
            },
            app: appOptions,
        } as DeprecatedThemeOptions
    },
    'qa': {
        sampleColor: blueGrey[800],
        options: {
            overrides: {
                MuiCssBaseline: {
                    html: {
                        ...darkScrollbar(),
                        //scrollbarWidth for Firefox
                        scrollbarWidth: "thin"
                    }
                },
                MuiSvgIcon: {
                    root: {
                        color: '#ffffff',  // Replace with the color of your choice
                    },
                },
            },
            palette: {
                mode: 'dark',
                primary: {
                    main: blueGrey[800],
                },
                secondary: {
                    main: '#d14a06',
                },
                text: {
                    primary: '#d1dbeb',
                },
            },
            app: appOptions,
        } as DeprecatedThemeOptions
    },
    'dev': {
        sampleColor: blueGrey[800],
        options: {
            palette: {
                primary: { main: blueGrey[800] },
                secondary: { main: lime[500] },
            },
            app: appOptions,
        } as DeprecatedThemeOptions
    },
    'kostas': {
        sampleColor: '#1f1137',
        options: {
            palette: {
                primary: { main: '#1f1137' },
                secondary: { main: yellow[400] },
            },
            app: appOptions,
        } as DeprecatedThemeOptions
    }
}

export type AppTheme = keyof typeof themes

type AppThemeContextType = {
    current: AppTheme
    setTheme: (th: AppTheme) => void
}

const AppThemeContext = createContext<AppThemeContextType>(undefined!)

export const useAppThemeContext = (): { themes: { name: AppTheme, sampleColor: string }[], current: AppTheme, setTheme: (th: AppTheme) => void } => {
    const ctx = useContext(AppThemeContext)
    return {
        themes: Object.keys(themes).map(th => ({ name: th as AppTheme, sampleColor: themes[th as AppTheme].sampleColor })),
        current: ctx.current,
        setTheme: ctx.setTheme
    }
}

const KEY = 'current'

const loadSettings = async (): Promise<AppTheme | undefined> => {
    const db = await openApplicationIdb()
    return await db.get('theme' as unknown as never, KEY)
}

const saveSettings = async (value: AppTheme) => {
    const db = await openApplicationIdb()
    await db.put('theme' as unknown as never, value, KEY)
}

export const AppThemeProvider: React.FC<React.PropsWithChildren> = (props: React.PropsWithChildren) => {
    const [state, setState] = useState<AppTheme>('prod')

    useEffect(() => {
        loadSettings().then(st => {
            st = st && Object.keys(themes).includes(st) ? st : undefined
            const th = st || (ENVIRONMENT === Environment.Dev ? 'dev' : ENVIRONMENT === Environment.Qa ? 'qa' : 'prod')
            setState(th)
        })
    }, [])

    const setTheme = useCallback((th: AppTheme) => {
        saveSettings(th).then(() => setState(th))
    }, [])

    const theme = createTheme(adaptV4Theme(_.merge({}, baseThemeOptions(themes[state].options), themes[state].options)))

    return (
        <AppThemeContext.Provider value={{ current: state, setTheme }}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    {props.children}
                </ThemeProvider>
            </StyledEngineProvider>
        </AppThemeContext.Provider>
    )
}


declare module '@mui/material/styles' {
    interface ThemeOptions {
        app: typeof appOptions;
    }

    interface Theme {
        app: typeof appOptions;
    }
}