import { HrefOrExplicitRequest } from "../components/core/DataGrid/ReadonlyDataGrid"
import { ApiClient } from "./ApiClient"
import { FilterWithValue, getApiDescription, getExport, getHref, getPaginated, SortCriterionWithValue } from "./Search"
import { JobStatusId, JobTypeId } from "./WellKnowIds"

export type JobViewModel =
    {
        jobId: number
        jobTypeId: JobTypeId
        jobStatusId: JobStatusId
        jobStatus: JobStatusId
        dateCreated: Date
    }

export type JobDiagnostic =
    {
        jobId: number
        jobDiagnosticId: number
        text: string
        dateCreated: Date
        level: string
    }

export class JobsApi {
    constructor(private readonly api: ApiClient) { }

    public scheduleInspections = (year: number, syncExecution?: boolean, isDryRun?: boolean) =>
        this.api.onUnexpected(
            this.api.execute<{ jobId: number }>(200, 'POST', `api/tenants/${this.api.tenantId}/jobs/schedule-inspections`, undefined, { year, syncExecution, isDryRun }, syncExecution ? 20 * 60 * 1000 : undefined),
            "Could not schedule inspections", null)

    public getJobs = (request: HrefOrExplicitRequest) =>
        getPaginated<JobViewModel>(this.api, getHref(request, `api/tenants/${this.api.tenantId}/jobs`))

    public describeJobsApi = () =>
        getApiDescription(this.api, `api/tenants/${this.api.tenantId}/jobs`)

    public getJobDiagnostics = (request: HrefOrExplicitRequest) =>
        getPaginated<JobDiagnostic>(this.api, getHref(request, `api/tenants/${this.api.tenantId}/jobs/diagnostics`))

    public getJobDiagnosticsExcel = (filters: FilterWithValue[], sorting: SortCriterionWithValue[]) =>
        getExport(this.api, `api/tenants/${this.api.tenantId}/jobs/diagnostics/export`, filters, sorting)

    public describeJobDiagnosticsApi = () =>
        getApiDescription(this.api, `api/tenants/${this.api.tenantId}/jobs/diagnostics`)
}
