import { ApiClient, BlobResponse } from "./ApiClient";
import { CertificationStateView } from "./CertificationStandards";
import { ApiDescription, combineQueryParams, EMPTY_API_DESCRIPTION, fakePaginated, FilterWithValue, mapFiltersToQueryParamsObject, mapSortingToQueryParamObject, Paginated, PaginationRequest, SortCriterionWithValue } from "./Search";
import { ActivityUnitSpecificKindId, ActivityUnitStatusId, ExportExcelTimeout } from "./WellKnowIds";

export type ActivityUnitApiViewModel = {
    customerId: number
    customerActivityUnitId: number
    persistentActivityUnitId: number
    snapshotStartDate: Date
    snapshotEndDate?: Date
    regionUnitName: string
    municipalityName: string
    communityName: string
    toponym?: string
    unitIdentifier?: string
    fragmentsDistinct: string[]
    unitCertificationStandards: CertificationStateView[]
    activityUnitStatusId: ActivityUnitStatusId
    isPropagatingMaterial?: boolean
    isSubcontractorOnlyLocation?: boolean
    isCorrection: boolean
    activityUnitSpecificKindId?: ActivityUnitSpecificKindId
}

export class ActivityUnitsApi {
    constructor(private readonly api: ApiClient) { }

    public get = (pagination: PaginationRequest, filters: FilterWithValue[], sorting: SortCriterionWithValue[]) => {
        const queryParams = combineQueryParams(pagination, mapFiltersToQueryParamsObject(filters), mapSortingToQueryParamObject(sorting))
        return this.getFromHref(`api/tenants/${this.api.tenantId}/activity-units?${queryParams.toString()}`)
    }

    public getFromHref = (href: string) =>
        this.api.onUnexpected(
            this.api.execute<Paginated<ActivityUnitApiViewModel>>(200, 'GET', href),
            "Could not load activity units",
            fakePaginated([]))

    public describe = () =>
        this.api.onUnexpected(
            this.api.execute<ApiDescription>(200, 'OPTIONS', `api/tenants/${this.api.tenantId}/activity-units`),
            "Could not load api description",
            EMPTY_API_DESCRIPTION)

    public getExcel = (filters: FilterWithValue[], sorting: SortCriterionWithValue[]) => {
        const fs = mapFiltersToQueryParamsObject(filters)
        const sort = mapSortingToQueryParamObject(sorting)
        const queryParams = combineQueryParams(fs, sort)
        return this.api.onUnexpected(
            this.api.execute<BlobResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/activity-units/export`, queryParams, undefined, ExportExcelTimeout, undefined, undefined, true),
            "Could not export given search to Excel",
            null)
    }
}
