import { ApiClient, BlobResponse } from "./ApiClient";
import { getResource } from "./Search";
import { BasicActivityGroupId, TicketStatusId } from "./WellKnowIds";

export type AssessmentComponent =
    {
        assessmentComponentId?: number
        customerActivityUnitId?: number
        productActivityCategoryId?: number
        byProductId: number | undefined
        organicCertificationCategoryId?: number
        quantity?: number
        complianceDate?: Date
        nonCompliances: {
            nonComplianceRuleId: number
            isRelapse: boolean
        }[]
    }

export type AssessmentBindingModel =
    {
        assessmentNote?: string
        dateOfAssessment?: Date
        regularInspectionDates?: string
        irregularInspectionDates?: string
        samplingDates?: string
        assessmentComponents: AssessmentComponent[]
    }

export type AssessmentViewModel = AssessmentBindingModel & {
    customerId: number
    inspectionId: number
    certificationStandardId: number
    ticketStatusId: TicketStatusId
    isAssigned?: boolean
    complianceDecisionId?: number
    note?: string
    applications: { applicationId: number, isActive: boolean }[]
}

export type Penalty =
    {
        nonCompliancePenaltyId: number
        isRelapse: boolean
        description: string
        abbreviation?: string
    }

export type RegulationRule =
    {
        nonComplianceRuleId: number
        regulationArticle: string
        description: string
        isCustomerReferring: boolean
        basicActivityGroupId?: BasicActivityGroupId
        penalties: Penalty[]
    }

export type RegulationViewModel =
    {
        regulationId: number
        description: string
        startYear: number
        endYear: number
        rules: RegulationRule[]
    }

export const EMPTY_REGULATION_VIEWMODEL: RegulationViewModel =
{
    regulationId: 0,
    description: '',
    startYear: 0,
    endYear: 0,
    rules: []
}

export type RegulatedComponentViewModel =
    { isProductComponent: boolean }
    & {
        customerActivityUnitId: number
        persistentActivityUnitId: number
        toponym?: string
        addressLine1?: string
        unitIdentifier?: string
        basicActivityGroupId: BasicActivityGroupId
        fragments: string[]
        name: string
        activityCategoryId: number
        quantity?: number
        organicCertificationCategoryId: number
        organicCertificationCategory: string
        byProductId?: number
        byProduct?: string
    } & { fragment?: string, attribute?: string }

export const EMPTY_ASSESSMENT: AssessmentViewModel =
{
    customerId: 0,
    inspectionId: 0,
    certificationStandardId: 0,
    ticketStatusId: TicketStatusId.Done,
    assessmentComponents: [],
    applications: [],
}

export type NonComplianceViewModel =
    {
        nonComplianceRuleId: number
        description: string
        regulationArticle: string
        isRelapse: boolean
    }

export type AssessmentResolution =
    {
        customerId: number
        hasGeneralNonCompliance: boolean
        distinctiveTitle: string
        certificationStandardId: number
        certificationStandard: string
        dateOfAssessment: Date
        endDateOfAssessment: Date
        perActivityUnit: {
            basicActivityGroup: string,
            activityUnit: string,
            complianceDate?: Date,
            organicCertificationCategory?: string,
            isModification: boolean,
            nonCompliances: NonComplianceViewModel[]
            penalties: Penalty[]
        }[],
        perProduct: {
            product: string
            byProduct?: string
            isVirtual: boolean
            productActivityCategoryId?: number
            fragmentLatin?: string
            organicCertificationCategory: string
            basicActivityGroup: string
            isCompliant?: boolean
            totalQuantity?: number
            nonCompliances: NonComplianceViewModel[]
            penalties: Penalty[]
        }[]
        summary: {
            products: { name: string, isVirtual: boolean, fragmentLatin?: string, byProduct?: string }[]
            organicCertificationCategory: string
            basicActivityGroup: string
        }[]
    }

export const EMPTY_ASSESSMENT_RESOLUTION: AssessmentResolution =
{
    customerId: 0,
    hasGeneralNonCompliance: false,
    distinctiveTitle: '',
    certificationStandardId: 0,
    certificationStandard: '',
    dateOfAssessment: new Date(),
    endDateOfAssessment: new Date(),
    perProduct: [],
    summary: [],
    perActivityUnit: [],
}

export class CertificationApi {
    constructor(private readonly api: ApiClient) { }

    public getRegulation = (certificationStandardId: number) =>
        this.api.onUnexpected(
            this.api.execute<RegulationViewModel>(200, 'GET', `api/tenants/${this.api.tenantId}/certification/standards/${certificationStandardId}/regulations/current`),
            "Could not load current regulation", EMPTY_REGULATION_VIEWMODEL)

    public getRegulatedComponents = (ticketId: number) =>
        this.api.onUnexpected(
            this.api.execute<{ isRevision: boolean, components: RegulatedComponentViewModel[] }>(200, 'GET', `api/tenants/${this.api.tenantId}/certification/tickets/${ticketId}/regulated-components`),
            "Could not load current regulation", { isRevision: false, components: [] })

    public upsertAssessment = (ticketId: number, model: AssessmentBindingModel) =>
        this.api.onUnexpected(
            this.api.execute<{ ticketId: number }>(200, 'PUT', `api/tenants/${this.api.tenantId}/tickets/${ticketId}/assessment`, undefined, model),
            "Could not save assessment", null)

    public completeAssessment = (ticketId: number) =>
        this.api.onUnexpected(
            this.api.execute<{ ticketId: number }>(200, 'PUT', `api/tenants/${this.api.tenantId}/tickets/${ticketId}/assessment/complete`),
            "Could not complete assessment", null)

    public getAssessment = (ticketId: number) =>
        this.api.onUnexpected(
            this.api.execute<AssessmentViewModel>(200, 'GET', `api/tenants/${this.api.tenantId}/tickets/${ticketId}/assessment`),
            "Could not load assessment", EMPTY_ASSESSMENT)

    public getAssessmentResolution = (ticketId: number) =>
        this.api.onUnexpected(
            this.api.execute<AssessmentResolution>(200, 'GET', `api/tenants/${this.api.tenantId}/tickets/${ticketId}/assessment/resolution`),
            "Could not load assessment resolution", EMPTY_ASSESSMENT_RESOLUTION)

    public getEffectiveProductsReport = (year: number) =>
        getResource<BlobResponse | null>(this.api, `api/tenants/${this.api.tenantId}/certification/reports/effective-products-per-year`, null, { blob: true, queryParams: { year }, timeout: this.api.maxTimeoutMs })

    public getUnitsSummaryReport = (year: number) =>
        getResource<BlobResponse | null>(this.api, `api/tenants/${this.api.tenantId}/certification/reports/units-summary-per-year`, null, { blob: true, queryParams: { year }, timeout: this.api.maxTimeoutMs })

    public getCropReport = (year: number) =>
        getResource<BlobResponse | null>(this.api, `api/tenants/${this.api.tenantId}/certification/reports/org-crop-per-year`, null, { blob: true, queryParams: { year }, timeout: this.api.maxTimeoutMs })

    public getAniReport = (year: number) =>
        getResource<BlobResponse | null>(this.api, `api/tenants/${this.api.tenantId}/certification/reports/org-ani-per-year`, null, { blob: true, queryParams: { year }, timeout: this.api.maxTimeoutMs })

    public getManufReport = (year: number) =>
        getResource<BlobResponse | null>(this.api, `api/tenants/${this.api.tenantId}/certification/reports/org-manuf-per-year`, null, { blob: true, queryParams: { year }, timeout: this.api.maxTimeoutMs })
}