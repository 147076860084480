import { ApiClient, BlobResponse } from "./ApiClient"
import { getResource } from "./Search"
import { ExportExcelTimeout } from "./WellKnowIds"

export type InspectionsPerMonth =
    {
        months: string[]
        entries: { inspectionStatus: string, values: number[] }[]
    }

export const EMPTY_INSPECTIONS_PER_MONTH: InspectionsPerMonth =
{
    months: [],
    entries: [],
}

export type SamplesPerActivityGroup =
    {
        basicActivityGroups: string[],
        samplePercentages: number[],
        dateFrom: Date,
        dateTo: Date,
    }

export const EMPTY_SAMPLES_PER_ACTIVITY_GROUP: SamplesPerActivityGroup =
{
    basicActivityGroups: [],
    samplePercentages: [],
    dateFrom: new Date(),
    dateTo: new Date(),
}

export enum AggregationType {
    PerUserSum = 1,
    Total = 2,
    TotalPerNew = 3,
    TotalPerStatus = 4,
}

export enum Granularity {
    Week = 1,
    Day = 2,
    None = 3,
}

export enum TimeSpan {
    All = 1,
    Month = 2,
    Week = 3,
    Year = 4,
    Quarter = 5,
    HalfYear = 6,
}

export type ApplicationStatistics =
    {
        labels: string[]
        entries: { lineLabel: string, values: number[] }[]
    }

export type InspectionsPerStandardDistinctCustomer =
    {
        standardName: string,
        activeCustomers: number,
        regularInspections: number
    }

export const EMPTY_INSPECTIONS_PER_STANDARD_DISTINCT: InspectionsPerStandardDistinctCustomer = {
    activeCustomers: 0,
    regularInspections: 0,
    standardName: ""
}

export type CaPerTimespan =
    {
        labels: Date[],
        perStandard: { certificationStandardId: number, count: number[] }[]
    }

export class StatisticsApi {
    constructor(private readonly api: ApiClient) { }

    public getCaPerTimespan = () =>
        getResource<CaPerTimespan | null>(this.api, `api/tenants/${this.api.tenantId}/statistics/ca/per-timespan`, null)

    public getInspectionsPerMonth = () =>
        this.api.onUnexpected(
            this.api.execute<InspectionsPerMonth>(200, 'GET', `api/tenants/${this.api.tenantId}/statistics/inspections/per-month`),
            "Could not load ipm statistics", EMPTY_INSPECTIONS_PER_MONTH)

    public getSamplesPerBasicActivityGroup = () =>
        this.api.onUnexpected(
            this.api.execute<SamplesPerActivityGroup>(200, 'GET', `api/tenants/${this.api.tenantId}/statistics/samples/per-group-percentage`),
            "Could not load spg statistics", EMPTY_SAMPLES_PER_ACTIVITY_GROUP)

    public getApplications = (aggregation: AggregationType, granularity: Granularity, timespan: TimeSpan) =>
        this.api.onUnexpected(
            this.api.execute<ApplicationStatistics>(200, 'GET', `api/tenants/${this.api.tenantId}/statistics/applications`, { aggregationType: aggregation, granularity: granularity, timespan }),
            "Could not load application statistics", { labels: [], entries: [] } as ApplicationStatistics)

    public getApplicationsExport = (aggregation: AggregationType, granularity: Granularity, timespan: TimeSpan) =>
        this.api.onUnexpected(
            this.api.execute<BlobResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/statistics/applications/export`, { aggregationType: aggregation, granularity: granularity, timespan }, undefined, ExportExcelTimeout, undefined, undefined, true),
            "Could not export application statistics", null)

    public getInspectionsPerStandardDistinctCustomer = () =>
        this.api.onUnexpected(
            this.api.execute<InspectionsPerStandardDistinctCustomer[]>(200, 'GET', `api/tenants/${this.api.tenantId}/statistics/inspections/per-standard-distinct-customer`),
            "Could not load ipSpC statistics", [] as InspectionsPerStandardDistinctCustomer[])
}
