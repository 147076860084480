import { ApiClient } from "./ApiClient"

export const EMPTY_ENTITY_RELATIONSHIP: EntityRelationshipBaseModel =
{
    entityRelationshipTypeId: 0,
    // destinationTaxRegistrationNumber: ''
}

export const EMPTY_SUBCONTRACTOR_RELATIONSHIP: EntityRelationshipBindingModel =
{
    entityRelationshipTypeId: 0,
    // destinationTaxRegistrationNumber: '',
}

export type EntityRelationshipBaseModel =
    {
        entityRelationshipTypeId: number
        destinationTaxRegistrationNumber?: string
        destinationInspectorId?: number
        basicActivityGroupId?: number
        note?: string
    }

export type EntityRelationshipBindingModel = EntityRelationshipBaseModel & Partial<SubcontractorTypeView>

export type EntityRelationshipViewModel =
    {
        entityRelationshipId: number
        entityRelationshipTypeId: number
        entityRelationshipTypeName: string
        destinationTaxRegistrationNumber: string
        destFullName: string
        inspectorDestId?: number
        customerDestId?: number
        applicationDestId?: number
        basicActivityGroupName: string
        sourceCustomerId?: number
        deletionAllowed: boolean,
        note?: string

    } & Partial<SubcontractorTypeView>

export type ReferralsViewModel =
    {
        firstName?: string
        lastName?: string
        distinctiveTitle?: string
        customerId: number
    }

export type EntityRelationshipCreated =
    {
        entityRelationshipId: number
    }

export type EntityRelationshipTypeView =
    {
        entityRelationshipTypeId: number
        entityRelationshipTypeName: string
        isDestinationCustomer?: boolean
        isDestinationInspector?: boolean
    }

export type SubcontractorTypeView =
    {
        subcontractorTypeId: number
        destinationExternalSubcontractorId?: number
        code?: string
        name: string
    }

export class EntityRelationshipsApi {
    constructor(private readonly api: ApiClient) { }

    public get = () =>
        this.api.onUnexpected(
            this.api.execute<EntityRelationshipTypeView[]>(200, 'GET', `api/entity-relationship-types`)
            , "Could not load relationship types", [])

    public getSubcontractorTypes = () =>
        this.api.onUnexpected(
            this.api.execute<SubcontractorTypeView[]>(200, 'GET', `api/entity-relationship-types/subcontractor-types`)
            , "Could not load subcontractor types", [])

    public add = (applicationId: number, c: EntityRelationshipBindingModel) =>
        this.api.onUnexpected(
            this.api.execute<EntityRelationshipCreated>(204, 'PUT', `api/tenants/${this.api.tenantId}/applications/${applicationId}/entity-relationship`, undefined, c)
            , "Could not create relationship", null)

    public getApplicationEntityRelationships = (applicationId: number) =>
        this.api.onUnexpected(
            this.api.execute<EntityRelationshipViewModel[]>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/entity-relationships`),
            "Could not load connections",
            [] as EntityRelationshipViewModel[])

    public getCustomerEntityRelationships = (customerId: number) =>
        this.api.onUnexpected(
            this.api.execute<EntityRelationshipViewModel[]>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/${customerId}/entity-relationships`),
            "Could not load connections",
            [] as EntityRelationshipViewModel[])

    public getInspectorReferrals = (inspectorId: number) =>
        this.api.onUnexpected(
            this.api.execute<ReferralsViewModel[]>(200, 'GET', `api/tenants/${this.api.tenantId}/inspectors/${inspectorId}/entity-relationships`),
            "Αδυναμία εμφάνισης πελατών",
            [] as ReferralsViewModel[])

    public deleteApplicationRelationship = (applicationId: number, entityRelationshipId: number) =>
        this.api.onUnexpected(
            this.api.execute<{}>(204, 'DELETE', `api/tenants/${this.api.tenantId}/applications/${applicationId}/entity-relationships/${entityRelationshipId}`)
            , "Could not delete the relationship", null)

    public deleteCustomerRelationship = (customerId: number, entityRelationshipId: number) =>
        this.api.onUnexpected(
            this.api.execute<{}>(204, 'DELETE', `api/tenants/${this.api.tenantId}/customers/${customerId}/entity-relationships/${entityRelationshipId}`)
            , "Could not delete the relationship", null)
}