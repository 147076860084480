import DateOnly from "../utils/dateOnly"
import { ApiClient } from "./ApiClient"
import { deleteResource, getResource, postResource } from "./Search"
import { VirtualAgreementStatusId } from "./WellKnowIds"

export type VirtualAgreementType =
    {
        id: number,
        name: string
    }

export type VirtualAgreementStatus =
    {
        id: number,
        name: string
    }

export type VirtualAgreement = {
    id: number,
    typeId: number,
    code?: string,
    startDate: DateOnly,
    endDate?: DateOnly,
    statusId: number,
    dateCreated: Date,
    createdUserId: number,
    dateModified: Date,
    modifiedUserId: number
}

export const EMPTY_VA: VirtualAgreement = {
    id: null as unknown as number,
    code: null as unknown as string,
    typeId: null as unknown as number,
    startDate: null as unknown as DateOnly,
    statusId: VirtualAgreementStatusId.Active,
} as VirtualAgreement


export type CodeOption = {
    code: string
}

export class VirtualAgreementsApi {
    constructor(private readonly api: ApiClient) { }

    public getVirtualAgreementTypes = () =>
        getResource<VirtualAgreementType[]>(this.api, `api/tenants/${this.api.tenantId}/virtual-agreements/types`, [] as VirtualAgreementType[])

    public getVirtualAgreementStatuses = () =>
        getResource<VirtualAgreementStatus[]>(this.api, `api/tenants/${this.api.tenantId}/virtual-agreements/statuses`, [] as VirtualAgreementStatus[])

    public getVirtualAgreements = (customerId: number, customerAgreementId: number) =>
        getResource<VirtualAgreement[]>(this.api, `api/tenants/${this.api.tenantId}/virtual-agreements/customers/${customerId}/agreement/${customerAgreementId}`, [] as VirtualAgreement[])

    public getVirtualAgreementCodeOptions = (customerId: number, customerAgreementId: number) =>
        getResource<CodeOption[]>(this.api, `api/tenants/${this.api.tenantId}/virtual-agreements/customers/${customerId}/agreement/${customerAgreementId}/code-options`, [] as CodeOption[])

    public updateVirtualAgreements = (customerId: number, customerAgreementId: number, virtualAgreements: VirtualAgreement[], createMixedAgreement?: boolean) =>
        postResource(this.api, `api/tenants/${this.api.tenantId}/virtual-agreements/customers/${customerId}/agreement/${customerAgreementId} `, { customerVirtualAgreements: virtualAgreements, createMixedAgreement })

    public deleteVirtualAgreement = (customerId: number, customerAgreementId: number, virtualAgreementId: number) =>
        deleteResource(this.api, `api/tenants/${this.api.tenantId}/virtual-agreements/customers/${customerId}/agreement/${customerAgreementId}/virtual-agreement/${virtualAgreementId}`)
}