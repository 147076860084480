export class TimeOnly {
    private readonly date: Date

    constructor(date: Date) {
        this.date = new Date(date)
        this.date.setSeconds(0)
        this.date.setMilliseconds(0)
    }

    get hours(): number {
        return this.date.getHours()
    }

    get minutes(): number {
        return this.date.getMinutes()
    }

    get seconds(): number {
        return this.date.getSeconds()
    }

    get milliseconds(): number {
        return this.date.getMilliseconds()
    }

    get rawDate(): Date {
        return this.date
    }

    toString(): string {
        return this.date.toTimeString()
    }

    isValid(): boolean {
        return !isNaN(this.date.getTime())
    }

    toLocaleString(locale: string = 'default'): string {
        return this.date.toLocaleTimeString(locale, {
            hour: '2-digit',
            minute: '2-digit',
        })
    }

    /// Convert to ISO 8601 format
    toJSON(): string {
        const hh = this.hours.toString().padStart(2, '0')
        const mm = this.minutes.toString().padStart(2, '0')
        const ss = this.seconds.toString().padStart(2, '0')
        const ms = this.milliseconds.toString().padStart(3, '0')
        return `${hh}:${mm}:${ss}.${ms}`
    }

    compareTo(other: TimeOnly): -1 | 0 | 1 {
        if (this.hours < other.hours) return -1
        if (this.hours > other.hours) return 1

        if (this.minutes < other.minutes) return -1
        if (this.minutes > other.minutes) return 1

        if (this.seconds < other.seconds) return -1
        if (this.seconds > other.seconds) return 1

        if (this.milliseconds < other.milliseconds) return -1
        if (this.milliseconds > other.milliseconds) return 1

        return 0
    }

    static parse(value: string): TimeOnly {
        const date = new Date()
        // parse iso 8601 time format 12:32:23.123
        const parts = value.split(':')
        date.setHours(parseInt(parts[0]))
        date.setMinutes(parseInt(parts[1]))
        date.setSeconds(parseFloat(parts[2]))

        return new TimeOnly(date)
    }
}
