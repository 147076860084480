import { ApiClient } from "./ApiClient"

export type LegalTypesView =
    {
        legalTypeId: number
        name: string
    }

export class LegalTypesApi {
    constructor(private readonly api: ApiClient) { }

    public get = () =>
        this.api.onUnexpected(
            this.api.execute<LegalTypesView[]>(200, 'GET', `api/legal-types`),
            "Could not load Legal types",
            []
        )
}
