import { Typography } from "@mui/material"
import { Lock } from '@mui/icons-material'
import React from "react"

export const ForbiddenPage = () => {
    return <>
        <Typography variant='h5' align='center' display='inline'>
            <Lock color='error' fontSize='inherit' />
            Δεν έχετε δικαιώματα πρόσβασης στην συγκεκριμένη οθόνη ή η ενέργεια που επιχειρήσατε δεν επιτρέπεται.
            Εάν πιστεύετε ότι πρόκειται για λάθος, επικοινωνήστε με τον υπεύθυνό σας.
        </Typography>
    </>
}