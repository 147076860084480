export type ContactInformation =
    {
        landlineNumber: ContactInformationComponent[]
        mobileNumber: ContactInformationComponent[]
        faxNumber: ContactInformationComponent[]
        email: ContactInformationComponent[]
        website: ContactInformationComponent[]
    }

export type ContactInformationComponent =
    {
        value: string
        isPrimary?: boolean
        isVerified?: boolean
    }

export const EMPTY_CONTACT_INFORMATION = { landlineNumber: [], email: [], faxNumber: [], mobileNumber: [], website: [] }