export type AddressLikeBindingModel =
    {
        addressLine1: string
        addressLine2?: string
        postalCode: string
        communityId: number
    }

export type AddressLikeFullModel =
    AddressLikeBindingModel &
    {
        regionName: string
        regionUnitName: string
        municipalityName: string
        municipalityUnitName: string
        communityName: string
    }

export type CustomerAddress =
    AddressLikeBindingModel &
    AddressLikeFullModel &
    {
        customerAddressId?: number
        addressLine1Latin?: string
    }


export const EMPTY_ADDRESS: CustomerAddress =
{
    addressLine1: '',
    postalCode: '',
    regionName: '',
    regionUnitName: '',
    municipalityName: '',
    municipalityUnitName: '',
    communityId: 0,
    communityName: ''
}

export const addressToFullString = (a: AddressLikeFullModel) => `${a.addressLine1}, ${a.postalCode}, ${a.communityName}, ${a.municipalityName}, ${a.regionUnitName}, ${a.regionName}`