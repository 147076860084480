import { ApiClient, BlobResponse } from "./ApiClient"
import { ContactInformation } from "./ContactInformation"
import { ApiDescription, combineQueryParams, EMPTY_API_DESCRIPTION, fakePaginated, FilterWithValue, mapFiltersToQueryParamsObject, mapSortingToQueryParamObject, Paginated, PaginationRequest, SortCriterionWithValue } from "./Search"
import { ExportExcelTimeout } from "./WellKnowIds"

type EvaluatorModelBase =
    {
        userId?: number
        firstName: string
        lastName: string
        middleName?: string
        taxRegistrationNumber?: string
        contactInformation?: Partial<ContactInformation>
        certificationStandards: { certificationStandardId: number }[]
    }

export type EvaluatorViewModel = EvaluatorModelBase & { evaluatorId: number }

export type EvaluatorBindingModel = EvaluatorModelBase & { evaluatorId?: number }

export const EMPTY_EVALUATOR: EvaluatorBindingModel =
{
    firstName: '',
    lastName: '',
    contactInformation: {},
    certificationStandards: [],
}

export type EvaluatorListingViewModel =
    {
        evaluatorId: number
        taxRegistrationNumber?: string
        firstName: string
        lastName: string
        certificationStandards: string[]
        username?: string
    }

export class EvaluatorsApi {
    constructor(private readonly api: ApiClient) { }

    public get = (pagination: PaginationRequest, filters: FilterWithValue[], sorting: SortCriterionWithValue[]) => {
        const queryParams = combineQueryParams(pagination, mapFiltersToQueryParamsObject(filters), mapSortingToQueryParamObject(sorting))
        return this.getFromHref(`api/tenants/${this.api.tenantId}/evaluators?${queryParams.toString()}`);
    }

    public getFromHref = (href: string) =>
        this.api.onUnexpected(
            this.api.execute<Paginated<EvaluatorListingViewModel>>(200, 'GET', href)
            , "Could not load evaluators", fakePaginated([] as EvaluatorListingViewModel[]))

    public describe = () =>
        this.api.onUnexpected(
            this.api.execute<ApiDescription>(200, 'OPTIONS', `api/tenants/${this.api.tenantId}/evaluators`)
            , "Could not load api description", EMPTY_API_DESCRIPTION)

    public getDetails = (evaluatorId: number) =>
        this.api.onUnexpected(
            this.api.execute<EvaluatorViewModel>(200, 'GET', `api/tenants/${this.api.tenantId}/evaluators/${evaluatorId}`)
            , "Could not load evaluator details", null)

    public upsert = (c: EvaluatorBindingModel) =>
        this.api.onUnexpected(
            this.api.execute<{ evaluatorId: number }>(200, 'PUT', `api/tenants/${this.api.tenantId}/evaluators${c.evaluatorId ? `/${c.evaluatorId}` : ''}`, undefined, c)
            , "Could not update evaluator", null)

    public getExcel = (filters: FilterWithValue[], sorting: SortCriterionWithValue[]) => {
        const queryParams = combineQueryParams(mapFiltersToQueryParamsObject(filters), mapSortingToQueryParamObject(sorting))
        return this.api.onUnexpected(
            this.api.execute<BlobResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/evaluators/export`, queryParams, undefined, ExportExcelTimeout, undefined, undefined, true),
            "Could not export given search to Excel",
            null)
    }
}