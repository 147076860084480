import { HrefOrExplicitRequest } from "../components/core/DataGrid/ReadonlyDataGrid"
import { ApiClient } from "./ApiClient"
import { IdDisplayPair } from "./Common"
import { deleteResource, getApiDescription, getHref, getPaginated, getResource, postResource, putResource } from "./Search"
import { ActivityUnitSpecificKindId, BasicActivityGroupId, LegalTypeId, SinglePaymentApplicationDocumentKind } from "./WellKnowIds"

export type SinglePaymentApplicationMapping = {
    singlePaymentApplicationMappingId: number,
    fragmentCode: string,
    fragmentText: string,
    variantCode?: string,
    variantText?: string,
    fragmentCategory?: string,
    attributeCategory?: string,
    variantCategory?: string,
    fragmentCategoryId?: number,
    attributeCategoryId?: number,
    variantCategoryId?: number,
    dateModified: Date,
    dateCreated: Date,
    modifiedUser: string,
    createdUser: string
    ignore: boolean
}

export type MissingMapping = {
    fragmentText: string
    variantText?: string
    singlePaymentApplicationMappingId?: number
}

export type AnalyzeDocumentResponse =
    {
        semanticModel: SemanticModel
        missingMappings: MissingMapping[]
    }

export type SemanticModel =
    {
        numberOfPages: number
        basicInformation?: SemanticBasicInformation
        activityUnits: SemanticActivityUnit[]
        analysisContext: AnalysisContext
        overallConfidenceLevel?: number
    }

export type SemanticActivityUnit =
    {
        include?: boolean
        communityId?: number
        toponym?: string
        areaHa?: number
        identifier?: string
        units?: number
        initialDate?: Date
        complianceDate?: Date
        snapshotStartDate?: Date
        organicCertificationCategoryId?: number
        basicActivityGroupId?: BasicActivityGroupId
        activityUnitSpecificKindId?: ActivityUnitSpecificKindId
        fragments: SemanticFragmentLine[]
        parsedModel: {
            sequenceNumber?: number
            community?: string
            toponym?: string
            areaHa?: number
            identifier?: string
        }
        analysisContext: AnalysisContext
    }

export type SemanticFragmentLine =
    {
        include?: boolean
        isPrimary?: boolean
        fragmentId?: number
        attributeId?: number
        variantId?: number
        areaHa?: number
        units?: number
        parsedModel: {
            fragmentText?: string
            variantText?: string
            areaHa?: number
            numberOfTreesLt4Years?: number
            numberOfTreesGt4Years?: number
        }
        analysisContext: AnalysisContext
    }

export type DiagnosticId = number

export type ConfidencePoint =
    {
        value: number
        level: ConfidenceLevel
    }

export type AnalysisContext =
    {
        errors: DiagnosticId[]
        warnings: DiagnosticId[]
        calculatedConfidenceLevel: ConfidencePoint
        confidencePoints: ConfidencePoint[]
        pageNumber?: number
    }

export enum ConfidenceLevel {
    Low = 1,
    Medium = 2,
    High = 3
}

export type SemanticBasicInformation =
    {
        taxOfficeId?: number
        taxRegistrationNumber?: string
        legalTypeId?: LegalTypeId
        lastName?: string
        firstName?: string
        middleName?: string
        dateOfBirth?: Date
        nationalIdentityNumber?: string
        landlineNumber?: string
        mobileNumber?: string
        parsedModel: {
            taxOffice?: string
            taxRegistrationNumber?: string
            legalType?: string
            lastName?: string
            firstName?: string
            middleName?: string
            dateOfBirth?: string
            nationalIdentityNumber?: string
            phone?: string
        }
        analysisContext: AnalysisContext
    }

export type UpsertMappingModel = {
    singlePaymentApplicationMappingId?: number
    fragmentCode: string
    fragmentText: string
    variantCode?: string
    variantText?: string
    fragmentCategoryId?: number
    attributeCategoryId?: number
    variantCategoryId?: number
    ignore: boolean
}
export const EMPTY_UPSERT_MAPPING_MODEL: UpsertMappingModel = {
    fragmentCode: '',
    fragmentText: '',
    ignore: false
}

export type UpsertMissingMappingModel =
    UpsertMappingModel & {
        shouldSave: boolean,
        originalFragmentText: string,
        originalVariantText?: string,
    }

export type MultiPageRange = ({ page: number } | { start?: number, end?: number })[]

export enum UnitsFilter {
    All = 1,
    CropOnly = 2,
    LivestockOnly = 3,
}

export class SinglePaymentApplicationsApi {
    constructor(private readonly api: ApiClient) { }

    public analyzeDocument = (fileInfo: File, documentKind: SinglePaymentApplicationDocumentKind, unitsFilter: UnitsFilter | undefined, pageRange: MultiPageRange | undefined) => {
        const data = new FormData()

        data.append('file', fileInfo)
        data.append('documentKind', documentKind.toString())
        if (unitsFilter) data.append('unitsFilter', unitsFilter.toString())
        if (pageRange) data.append('pageRange', JSON.stringify(pageRange))

        return postResource<FormData, AnalyzeDocumentResponse>(this.api, `api/tenants/${this.api.tenantId}/single-payment-applications/analyze`, data, { timeout: this.api.maxTimeoutMs })
    }

    public getSpaMappings = (request: HrefOrExplicitRequest) =>
        getPaginated<SinglePaymentApplicationMapping>(this.api, getHref(request, `api/tenants/${this.api.tenantId}/single-payment-applications/mappings`))

    public describeSpaMappingApi = () =>
        getApiDescription(this.api, `api/tenants/${this.api.tenantId}/single-payment-applications/mappings`)

    public batchDeleteMappings = (idsToDelete: number[]) =>
        deleteResource(this.api, `api/tenants/${this.api.tenantId}/single-payment-applications/mappings/batch`, { idsToDelete: idsToDelete })

    public batchUpsertMappings = (mappings: UpsertMappingModel[]) =>
        putResource(this.api, `api/tenants/${this.api.tenantId}/single-payment-applications/mappings/batch`, { mappings: mappings })

    public batchAddMissingMappings = (mappings: UpsertMissingMappingModel[], semanticModel: SemanticModel) =>
        postResource<{ mappings: UpsertMissingMappingModel[], semanticModel: SemanticModel }, { semanticModel: SemanticModel }>(this.api, `api/tenants/${this.api.tenantId}/single-payment-applications/mappings/missing/add`, { semanticModel: semanticModel, mappings: mappings })

    public importToApplication = (applicationId: number, semanticModel: SemanticModel) =>
        postResource(this.api, `api/tenants/${this.api.tenantId}/single-payment-applications/import/applications/${applicationId}`, { semanticModel: semanticModel }, { timeout: this.api.maxTimeoutMs })

    public getDiagnosticTypes = () =>
        getResource<{ diagnosticTypes: IdDisplayPair[] }>(this.api, `api/tenants/${this.api.tenantId}/single-payment-applications/diagnostic-types`, { diagnosticTypes: [] })
}
