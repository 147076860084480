import { ApiClient } from "./ApiClient"

export type CertificationStandardModel =
    {
        certificationStandardId: number
    }

export type CertificationState =
    {
        certificationStandardId: number
        organicCertificationCategoryId: number
        initialDate: Date
        complianceDate?: Date
    }

export type CertificationStateView =
    {
        certificationStandardName: string
        organicCertificationCategoryName: string
        initialDate: Date
        complianceDate?: Date
    }

export type CertificationStandardView =
    {
        certificationStandardId: number
        name: string
        isDefault: boolean
    }

export class CertificationStandardsApi {
    constructor(private readonly api: ApiClient) { }

    public get = (includeUnsupported?: boolean) =>
        this.api.onUnexpected(
            this.api.execute<CertificationStandardView[]>(200, 'GET', `api/certification-standards`, { includeUnsupported: includeUnsupported }),
            "Could not load certification standards",
            [] as CertificationStandardView[])
}