import DateOnly from "../utils/dateOnly"
import { idOrEmptyRoute } from "../utils/utils"
import { ApiClient } from "./ApiClient"

export type ProgrammeViewModel =
    {
        programmeId: number
        name: string
        startDate: DateOnly
        endDate?: DateOnly
        paymentDates: Date[]
    }

export type ProgrammeModel = Omit<ProgrammeViewModel, 'programmeId'>

export const EMPTY_PROGRAMME: ProgrammeModel =
{
    name: '',
    startDate: null as unknown as DateOnly,
    paymentDates: []
}

export class ProgrammesApi {
    constructor(private readonly api: ApiClient) { }

    public get = () =>
        this.api.onUnexpected(
            this.api.execute<ProgrammeViewModel[]>(200, 'GET', `api/tenants/${this.api.tenantId}/programmes`),
            "Could not load programmes", [] as ProgrammeViewModel[])

    public getDetails = (programmeId: number) =>
        this.api.onUnexpected(
            this.api.execute<ProgrammeViewModel>(200, 'GET', `api/tenants/${this.api.tenantId}/programmes/${programmeId}`),
            "Could not load programme", EMPTY_PROGRAMME as ProgrammeViewModel)

    public upsert = (id: number | undefined, c: ProgrammeModel) =>
        this.api.onUnexpected(
            this.api.execute<{ programmeId: number }>(200, 'PUT', `api/tenants/${this.api.tenantId}/programmes${idOrEmptyRoute(id)}`, undefined, c),
            "Could not update programme", null)

    public delete = (programmeId: number) =>
        this.api.onUnexpected(
            this.api.execute<{}>(204, 'DELETE', `api/tenants/${this.api.tenantId}/programmes/${programmeId}`),
            "Could not delete programme", null)
}