import { HrefOrExplicitRequest } from "../components/core/DataGrid/ReadonlyDataGrid"
import { toISOStringWIthTimezone } from "../utils/utils"
import { ApiClient, BlobResponse } from "./ApiClient"
import { IdResult } from "./Common"
import { FilterWithValue, SortCriterionWithValue, getApiDescription, getExport, getHref, getPaginated, getResource, postResource } from "./Search"
import { AgreementStatusId } from "./WellKnowIds"

export type AgreementListingViewModel =
    {
        customerAgreementId: number
        customerId: number
        startDate: Date
        endDate?: Date
        effectiveEndDate?: Date
        status: string
        certificationStandard: string
        distinctiveTitle: string
    }

export type CustomerAgreementViewModel =
    {
        customerAgreementId: number
        documentId: number
        deactivationDocumentId?: number
        startDate: Date
        endDate?: Date
        effectiveEndDate?: Date
        status: string
        deactivationReasonOther?: string
        agreementStatusId: AgreementStatusId
        certificationStandard: string
        certificationStandardId: number
        deactivationReasonId?: number
    }

export type DeactivationReasonViewModel =
    {
        deactivationReasonId: number
        requiresDocument?: boolean
        reason: string
    }

export type AgreementCheck =
    {
        needsInspectionDateSuggestion: boolean
        needsAgreement: boolean
        diffCertificationStandardIds: number[]
        agreementCertificationStandardIds: number[]
    }

export type CustomerCertificationStandard =
    {
        certificationStandardId: number
    }

export type UpsertAgreementModel =
    {
        startDate: Date
        endDate?: Date
    }

export class CustomerAgreementsApi {
    constructor(private readonly api: ApiClient) { }

    public deactivate = (customerAgreementId: number, effectiveEndDate: Date, bypassApplicationCheck: boolean) =>
        this.api.onUnexpected(
            this.api.execute<void>(204, 'DELETE', `api/tenants/${this.api.tenantId}/customer-agreements/${customerAgreementId}`, undefined, { effectiveEndDate: effectiveEndDate, bypassApplicationCheck: bypassApplicationCheck }),
            "Could not deactivate agreement", undefined)

    public extend = (customerAgreementId: number, endDate: Date, file: File | undefined, startDate: Date) => {
        const formData = new FormData()
        formData.append('endDate', toISOStringWIthTimezone(endDate))
        formData.append('startDate', toISOStringWIthTimezone(startDate))
        if (file)
            formData.append('file', file)

        return postResource(this.api, `api/tenants/${this.api.tenantId}/customer-agreements/${customerAgreementId}/extend`, formData, { timeout: this.api.maxTimeoutMs, expected: 204 })
    }

    public upsert = (customerId: number, customerAgreementId: number, model: UpsertAgreementModel) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'PUT', `api/tenants/${this.api.tenantId}/customers/${customerId}/agreements/${customerAgreementId}`, undefined, model),
            "Could not upsert agreement", null)

    public undoStatus = (customerId: number, customerAgreementId: number, redo: boolean, effectiveEndDate: Date | undefined) =>
        postResource(this.api, `api/tenants/${this.api.tenantId}/customers/${customerId}/agreements/${customerAgreementId}`, { redo, effectiveEndDate })

    public requestDeactivation = (customerAgreementId: number, deactivationReasonId: number | undefined, deactivationReasonOther: string | undefined, file: File | undefined) => {
        const formData = new FormData()
        if (deactivationReasonId)
            formData.append('deactivationReasonId', deactivationReasonId?.toString())
        if (deactivationReasonOther)
            formData.append('deactivationReasonOther', deactivationReasonOther)
        if (file)
            formData.append('file', file)
        return postResource<FormData, IdResult>(this.api, `api/tenants/${this.api.tenantId}/customer-agreements/${customerAgreementId}/request-deactivation`, formData, { timeout: this.api.maxTimeoutMs })
    }

    public getAgreementsList = (request: HrefOrExplicitRequest) =>
        getPaginated<AgreementListingViewModel>(this.api, getHref(request, `api/tenants/${this.api.tenantId}/customer-agreements`))

    public describeAgreementsApi = () =>
        getApiDescription(this.api, `api/tenants/${this.api.tenantId}/customer-agreements`)

    public getAgreementsListExcel = (filters: FilterWithValue[], sorting: SortCriterionWithValue[]) =>
        getExport(this.api, `api/tenants/${this.api.tenantId}/customer-agreements/export`, filters, sorting)

    public getDeactivationReasons = () =>
        this.api.onUnexpected(
            this.api.execute<DeactivationReasonViewModel[]>(200, 'GET', `api/tenants/${this.api.tenantId}/customer-agreements/deactivation-reasons`),
            "Could not load deactivation reasons", [] as DeactivationReasonViewModel[])

    public getCustomerAgreementsList = (customerId: number) =>
        this.api.onUnexpected(
            this.api.execute<CustomerAgreementViewModel[]>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/${customerId}/agreements`),
            "Could not load customer agreements list", [] as CustomerAgreementViewModel[])

    public getApplicationAgreementCheck = (applicationId: number) =>
        this.api.onUnexpected(
            this.api.execute<AgreementCheck>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/agreement-check`),
            "Could not load agreement check", null)

    public getCertificationStandards = (customerId: number) =>
        this.api.onUnexpected(
            this.api.execute<CustomerCertificationStandard[]>(200, 'GET', `api/tenants/${this.api.tenantId}/customers/${customerId}/certification-standards`),
            "Could not load certification standards", [] as CustomerCertificationStandard[])

    public getThreeMonthAgreementsReport = (month: number, year: number) =>
        getResource<BlobResponse | null>(this.api, `api/tenants/${this.api.tenantId}/customer-agreements/reports/three-month-list`, null, { blob: true, queryParams: { month, year } })


    public getCustomerAgreement = (customerId: number, agreementId: number) =>
        getResource<CustomerAgreementViewModel | null>(this.api, `api/tenants/${this.api.tenantId}/customers/${customerId}/agreements/${agreementId}`, null)
}