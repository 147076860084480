import { toISOStringWIthTimezone } from "../utils/utils"
import { ApiClient, BlobResponse } from "./ApiClient"
import { getResource } from "./Search"

export class ReportsApi {
    constructor(private api: ApiClient) { }

    public getEvaluationReport = (startDate: Date, endDate: Date) =>
        getResource<BlobResponse, null>(this.api, `api/tenants/${this.api.tenantId}/reports/evaluation/export`, null, { timeout: 0, blob: true, queryParams: { startDate: toISOStringWIthTimezone(startDate), endDate: toISOStringWIthTimezone(endDate) } })
}
