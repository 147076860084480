import { generatePath } from "react-router-dom"
import { AnsweredDigitalDocumentId, DigitalDocumentTemplateId } from './api/DigitalDocument'
import { mangle, unmangle } from "./utils/utils"

const routePrefix = 'web'

export const routes =
{
    "DOCUMENTATION":
    {
        template: `/documentation`
    },
    "ROOT":
    {
        template: `/`,
        catchAll: '*',
    },
    "LOGOUT":
    {
        template: `/logout`
    },
    "HOMEPAGE":
    {
        template: `/${routePrefix}`,
        catchAll: `/${routePrefix}/*`,
    },
    "DOCUMENTS_VIEWER":
    {
        template: `/${routePrefix}/viewer`
    },
    "REPORTS":
    {
        template: `/${routePrefix}/reports`
    },
    "EXTERNAL_USERS_MANAGEMENT":
    {
        template: `/${routePrefix}/external-users/manage`
    },
    "TICKET_MANAGEMENT":
    {
        template: `/${routePrefix}/tickets/manage`
    },
    "TICKETS_LIST":
    {
        template: `/${routePrefix}/tickets/list`
    },
    "TICKET_DETAILS":
    {
        template: `/${routePrefix}/tickets/:ticketId`,
        generate: (id: number) => generatePath(routes.TICKET_DETAILS.template, { ticketId: mangle(0xb4b47070, id) }),
        parseId: (params: string) => unmangle(0xb4b47070, params),
    },
    "TICKET_INSPECTION_ASSESSMENT":
    {
        template: `/${routePrefix}/tickets/assessment/:ticketId`,
        generate: (id: number) => generatePath(routes.TICKET_INSPECTION_ASSESSMENT.template, { ticketId: mangle(0xb4b47070, id) }),
        parseId: (params: string) => unmangle(0xb4b47070, params),
    },
    "APPLICATION_NEW":
    {
        template: `/${routePrefix}/applications/new`
    },
    "APPLICATION_DETAILS":
    {
        template: `/${routePrefix}/applications/:applicationId`,
        generate: (id: number) => generatePath(routes.APPLICATION_DETAILS.template, { applicationId: mangle(0xb00b7007, id) }),
        parseId: (params: string) => unmangle(0xb00b7007, params)
    },
    "APPLICATION_SEARCH":
    {
        template: `/${routePrefix}/applications`
    },
    "CUSTOMER_SUMMARIZED_VIEW": {
        template: `/${routePrefix}/customers/summarized`,
    },
    "CUSTOMER_DETAILS":
    {
        template: `/${routePrefix}/customers/:customerId`,
        generate: (id: number) => generatePath(routes.CUSTOMER_DETAILS.template, { customerId: mangle(0xcafebabe, id) }),
        parseId: (params: string) => unmangle(0xcafebabe, params),
    },
    "CUSTOMER_SEARCH":
    {
        template: `/${routePrefix}/customers`
    },
    "AGREEMENTS_SEARCH":
    {
        template: `/${routePrefix}/customers/agreements`
    },
    "ACTIVITY_UNIT_SEARCH":
    {
        template: `/${routePrefix}/customers/activity-units`
    },
    "SUBCONTRACTORS_SEARCH":
    {
        template: `/${routePrefix}/subcontractors`
    },
    "CONSULTANTS_SEARCH":
    {
        template: `/${routePrefix}/consultants`
    },
    "CALCULATOR":
    {
        template: `/${routePrefix}/calculator`
    },
    "INSPECTOR_DETAILS_NEW":
    {
        template: `/${routePrefix}/inspectors/new`
    },
    "INSPECTOR_DETAILS":
    {
        template: `/${routePrefix}/inspectors/:inspectorId`,
        generate: (id: number) => generatePath(routes.INSPECTOR_DETAILS.template, { inspectorId: id })
    },
    "INSPECTOR_SEARCH":
    {
        template: `/${routePrefix}/inspectors`
    },
    "INSPECTOR_STATISTICS":
    {
        template: `/${routePrefix}/inspectors-statistics`
    },
    "EVALUATOR_DETAILS_NEW":
    {
        template: `/${routePrefix}/evaluators/new`
    },
    "EVALUATOR_DETAILS":
    {
        template: `/${routePrefix}/evaluators/:evaluatorId`,
        generate: (id: number) => generatePath(routes.EVALUATOR_DETAILS.template, { evaluatorId: id })
    },
    "EVALUATOR_SEARCH":
    {
        template: `/${routePrefix}/evaluators`
    },
    "PROGRAMMES_SEARCH":
    {
        template: `/${routePrefix}/programmes`
    },
    "ACTIVITY_CATEGORIES_SEARCH":
    {
        template: `/${routePrefix}/activity-categories`
    },
    "PRICING_COMPONENTS":
    {
        template: `/${routePrefix}/pricing/components`
    },
    "PRICING_CATALOGUES":
    {
        template: `/${routePrefix}/pricing/catalogues`
    },
    "PRICING_CATALOGUE_DETAILS_NEW":
    {
        template: `/${routePrefix}/pricing/catalogues/new`
    },
    "PRICING_CATALOGUE_PLAYGROUND":
    {
        template: `/${routePrefix}/pricing/catalogue/playground`
    },
    "PRICING_CATALOGUE_DETAILS":
    {
        template: `/${routePrefix}/pricing/catalogues/:catalogueId`,
        generate: (id: number) => generatePath(routes.PRICING_CATALOGUE_DETAILS.template, { catalogueId: id }),
    },
    "INSPECTION_DETAILS":
    {
        template: `/${routePrefix}/inspections/:inspectionId`,
        generate: (id: number) => generatePath(routes.INSPECTION_DETAILS.template, { inspectionId: mangle(0xcafeb00b, id) }),
        parseId: (params: string) => unmangle(0xcafeb00b, params),
    },
    "INSPECTIONS_LIST":
    {
        template: `/${routePrefix}/inspections/list`
    },
    "INSPECTIONS_COSTS":
    {
        template: `/${routePrefix}/inspections-reimbursements/list`
    },
    "INSPECTIONS_MANAGEMENT":
    {
        template: `/${routePrefix}/inspections`
    },
    "INSPECTIONS_SCHEDULING":
    {
        template: `/${routePrefix}/inspections/scheduling`
    },
    "SAMPLES_SEARCH":
    {
        template: `/${routePrefix}/samples`
    },
    "SAMPLE_KINDS":
    {
        template: `/${routePrefix}/sample-kinds`
    },
    "ACTIVE_SUBSTANCES":
    {
        template: `/${routePrefix}/active-substances`
    },
    "ANALYSIS_LABS":
    {
        template: `/${routePrefix}/analysis-labs`
    },
    "SAMPLE_DETAILS":
    {
        template: `/${routePrefix}/samples/:sampleId`,
        generate: (id: number) => generatePath(routes.SAMPLE_DETAILS.template, { sampleId: mangle(0xcaf4b44b, id) }),
        parseId: (params: string) => unmangle(0xcaf4b44b, params),
    },
    "DOCUMENTS_INBOUND": {
        template: `/${routePrefix}/documents/inbound`,
    },
    "DOCUMENTS_OUTBOUND": {
        template: `/${routePrefix}/documents/outbound`,
    },
    "INSPECTION_PAYMENTS_MANAGEMENT":
    {
        template: `/${routePrefix}/inspection-payments`,
    },
    "INSPECTIONS_COSTS_MANAGEMENT":
    {
        template: `/${routePrefix}/inspection-reimbursement/manage`,
    },
    "USER_MANAGEMENT": {
        template: `/${routePrefix}/users`,
    },
    "GLOBAL_DIAGNOSTICS": {
        template: `/${routePrefix}/diagnostics`,
    },
    "INSPECTION_PAYMENTS_MANAGEMENT_FOR_FINANCIAL":
    {
        template: `/${routePrefix}/financial/inspection-payments`,
    },
    "INSPECTIONS_COSTS_MANAGEMENT_FOR_FINANCIAL":
    {
        template: `/${routePrefix}/financial/inspection-reimbursement/manage`,
    },
    "ADMIN_PANEL":
    {
        template: `/${routePrefix}/admin-panel`
    },
    "DIGITAL_DOCUMENTS_MANAGEMENT":
    {
        template: `/${routePrefix}/digital-documents/manage`
    },
    "DIGITAL_DOCUMENTS_MANAGEMENT_PREVIEW":
    {
        template: `/${routePrefix}/digital-documents/manage/templates/:templateId/preview/:previewDocumentId`,
        generate: (id: DigitalDocumentTemplateId, previewDocumentId: AnsweredDigitalDocumentId) => generatePath(routes.DIGITAL_DOCUMENTS_MANAGEMENT_PREVIEW.template, { templateId: id, previewDocumentId: previewDocumentId }),
        parseTemplateId: (params: string) => Number.parseInt(params) as DigitalDocumentTemplateId,
        parsePreviewDocumentId: (params: string) => Number.parseInt(params) as AnsweredDigitalDocumentId,
    },
    "PLAYGROUND_ANNOTATIONS":
    {
        template: `/${routePrefix}/playground/annotations`
    },
    "PLAYGROUND_KDAWG":
    {
        template: `/${routePrefix}/playground/k-dawg`
    },
    "PLAYGROUND_CUSTOMER_TIMELINE":
    {
        template: `/${routePrefix}/playground/customer-timeline`
    },
    "BIO_GPT":
    {
        template: `/${routePrefix}/bio-gpt`
    },
    "ACCOUNTING_INTEGRATION":
    {
        template: `/${routePrefix}/accounting/integrations`
    },
    "SINGLE_PAYMENT_APPLICATION_MAPPINGS_SEARCH":
    {
        template: `/${routePrefix}/single-payment-application-mappings`
    },
}

export default routes