import { Error } from "@mui/icons-material"
import { Grid, Typography } from "@mui/material"
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState } from "react"
import { SupportedLanguage, TenantId } from "../../../api/WellKnowIds"
import { caseNever } from "../../../utils/never"
import { useUserInfoContext } from "../Contexts/UserInfoContext"
import { openApplicationIdb } from "../LocalStore/IndexedDb"
import { InternationalizationContext, InternationalizationContextType } from "./InternationalizationContext"

const useStyles = makeStyles(theme => ({
    container: {
        width: '100vw',
        height: '100vh',
        justifyItems: 'center',
        background: theme.palette.primary.main,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        color: 'white',
        justifyContent: 'center',
    },
}))

export const InternationalizationContainer: React.FC<React.PropsWithChildren> = props => {
    const { userInfo } = useUserInfoContext()
    const classes = useStyles()
    const [state, setState] = useState<{ language?: SupportedLanguage; tenant?: TenantId; }>({})

    useEffect(() => {
        openApplicationIdb()
            .then(async (db) => {
                const settings = await db.get('settings', 'internationalization')
                setState(st => {
                    if (settings)
                        return st.tenant !== settings.tenant || st.language !== settings.language ? {
                            ...st,
                            tenant: settings.tenant ?? userInfo.preferredTenantId,
                            language: settings.language ?? userInfo.preferredLanguage,
                        } : st
                    else
                        return { ...st, tenant: userInfo.preferredTenantId, language: userInfo.preferredLanguage }
                })
            })
    }, [userInfo.preferredLanguage, userInfo.preferredTenantId])

    useEffect(() => {
        const lang = state.language
        if (!lang) return
        switch (lang) {
            case SupportedLanguage.Greek: document.documentElement.lang = 'el'; (document.documentElement as any).locale = 'el-GR'; break
            case SupportedLanguage.English: document.documentElement.lang = 'en'; (document.documentElement as any).locale = 'en-GB'; break
            case SupportedLanguage.Bulgarian: document.documentElement.lang = 'bg'; (document.documentElement as any).locale = 'bg-BG'; break
            default: return caseNever(lang)
        }
        const settings = { tenant: state.tenant, language: state.language }
        openApplicationIdb().then(db => db.put('settings', settings, 'internationalization'))
    }, [state.language])

    const setLanguage = (l: SupportedLanguage) => setState(st => ({ ...st, language: l }))
    const setTenant = (t: TenantId) => setState(st => ({ ...st, tenant: t }))

    const provider: InternationalizationContextType | undefined =
        userInfo.preferredTenantId ?
            {
                language: state.language ?? userInfo.preferredLanguage,
                tenant: state.tenant ?? userInfo.preferredTenantId,
                setLanguage: setLanguage,
                setTenant: setTenant,
            } : undefined

    // NB forcing a full reload by using tenant+language as a key
    return provider
        ? <InternationalizationContext.Provider key={`${provider.tenant}-${provider.language}`} value={provider}>
            {props.children}
        </InternationalizationContext.Provider> :
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" className={classes.container}>
            <div className={classes.wrapper}>
                <Typography variant='h6'><Error color='error' /> Ο διαχειριστής του συστήματος δεν σας έχει δώσει πρόσβαση σε καμία από τις υποστηριζόμενες Περιοχές. Επικοινωνήστε μαζί του άμεσα.</Typography>
            </div>
        </Grid>
}
