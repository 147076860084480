import { toISOStringWIthTimezone } from "../utils/utils"
import { ActivityCategoryView, BasicActivityGroupView } from "./ActivityCategory"
import { AddressLikeBindingModel, AddressLikeFullModel, CustomerAddress } from "./Address"
import { ApiClient, BlobResponse } from "./ApiClient"
import { CertificationState, CertificationStateView } from "./CertificationStandards"
import { IdDisplayPair } from "./Common"
import { ApplicantConsultantAssignmentViewModel } from "./Consultant"
import { ContactInformation } from "./ContactInformation"
import { ActivityUnitRecordListingViewModelBase, ProgrammesInfoViewModel } from "./Customer"
import { CustomerViewModel } from "./Inspection"
import { LegalRepresentativeBase } from "./LegalRepresentative"
import { NaturalPersonBasicProperties } from "./Person"
import { ProgrammeViewModel } from "./Programme"
import { ApiDescription, EMPTY_API_DESCRIPTION, FilterWithValue, Paginated, PaginationRequest, SortCriterionWithValue, combineQueryParams, fakePaginated, getResource, mapFiltersToQueryParamsObject, mapSortingToQueryParamObject, postResource, putResource } from "./Search"
import { SubcontractorRelationshipListingViewModel, SubcontractorViewModel } from "./Subcontractors"
import { VirtualAgreement } from "./VirtualAgreements"
import { ActivityUnitSpecificKindId, ActivityUnitStatusId, ApplicationStatusId, BasicActivityGroupId, CheckResultId, DiffType, ExportExcelTimeout, LegalTypeId, SuggestedModelActionId, TagId } from "./WellKnowIds"


export type ApplicationListingViewModel =
    {
        applicationId: number
        dateOfApplication: Date
        taxRegistrationNumber: string
        customerId?: number
        distinctiveTitle?: string
        applicationStatusId: ApplicationStatusId
        applicationStatus: string
        createdUser: string
        modifiedUser: string
        dateModified: Date
        dateCreated: Date
        dateOfMerge?: Date
        mergeUser?: string
        changeTypes: number[]
    }

export type ChangesInfo =
    {
        tagId: number
        name: number
        isActivityGroupAddition: boolean
        isArchivedActivityGroup: boolean
    }

export type ApplicationCustomerDetailsBindingModel =
    Partial<NaturalPersonBasicProperties>
    & {
        applicationId: number
        customerId?: number
        legacyIds: string[]

        legalTypeId?: LegalTypeId
        taxRegistrationNumber: string

        dateOfBirth?: Date
        distinctiveTitle: string
        distinctiveTitleLatin?: string

        previousOrganizationId?: number
        previousOrganizationDate?: Date

        taxOfficeId?: number

        address?: Partial<CustomerAddress>
        mailingAddress?: Partial<CustomerAddress>
        contactInformation?: Partial<ContactInformation>
        operationalLicense?: string
        businessRegistration?: string
        businessTitle?: string
        businessTitleLatin?: string
        legalRepresentative?: Partial<LegalRepresentativeBase>
        note?: string
        significance?: number
        gdprConsent?: boolean

        firstNameLatin?: string
        lastNameLatin?: string
        middleNameLatin?: string
    }

export const EMPTY_APPLICATION_CUSTOMER_DETAILS: ApplicationCustomerDetailsBindingModel =
{
    distinctiveTitle: '',
    taxRegistrationNumber: '',
    legacyIds: [],
    applicationId: 0
}

export type ApplicationUpdated = { applicationId: number }

export type Application =
    {
        applicationId?: number
        customerId?: number
        dateOfApplication: Date
        taxRegistrationNumber: string
        note?: string
        createdUser: string
        dateCreated: Date
        applicationStatusId: ApplicationStatusId
        applicationStatus: string
        inspectionId?: number
        suggestedInspectionDate?: Date
        isExternalExistingCustomerApplication: boolean
        isExternalUserFirstApplication: boolean
    }

export const EMPTY_APPLICATION: Application =
{
    dateOfApplication: null as unknown as Date,
    taxRegistrationNumber: "",
    applicationStatusId: 0 as any,
    applicationStatus: "",
    createdUser: "",
    dateCreated: null as unknown as Date,
    isExternalExistingCustomerApplication: false,
    isExternalUserFirstApplication: false,
}

export type ApplicationDetails = { applicationId: number } & ApplicationCustomerDetailsBindingModel

export type ActivityGroup = ActivityCategoryView

export type ActivityGroupAdded =
    {
        isReinstatement?: boolean
    }

export type PropertyBindingModel =
    AddressLikeBindingModel &
    {
        applicationPropertyId?: number
    }

export type PropertyListingViewModel = PropertyBindingModel & AddressLikeFullModel & { applicationPropertyId: number }

export type PropertyViewModel = PropertyBindingModel & { applicationPropertyId: number }

export const EMPTY_PROPERTY: PropertyBindingModel =
{
    addressLine1: '',
    communityId: 0,
    postalCode: '',
}

export type PropertyAdded = { applicationPropertyId: number }

export type ActivityUnitFragmentViewModel =
    {
        applicationActivityUnitFragmentId?: number
        activityCategoryId: number
        name: string
        units?: number
        squareMeters?: number
        areaStremmata?: number
        isPrimaryFragment?: boolean
        code?: string
        attributes: ActivityUnitAttributeBindingModel[]
        products: ActivityUnitProductBindingModel[]
        variants: ActivityUnitVariantBindingModel[]
    }

export type ActivityUnitProductBindingModel =
    {
        activityCategoryId: number
    }

export type ActivityUnitVariantBindingModel =
    {
        activityCategoryId: number
    }

export type ActivityUnitAttributeBindingModel =
    {
        activityCategoryId: number
    }

export const EMPTY_APPLICATION_ACTIVITY_UNIT_VIEW: ActivityUnitViewModel =
{
    applicationActivityUnitId: 0,
    persistentActivityUnitId: null as unknown as number,
    activityUnitStatusId: 0 as any,
    isCorrectable: false,
    isCorrection: false,
    communityId: 0,
    fragments: [],
    unitCertificationStandards: [],
    snapshotStartDate: null as unknown as Date,
    programmes: []
}

export type UnitHint =
    {
        locationHint?: {
            communityId: number
        }
        toponymHints: string[]
        certificationStandards: number[]
    }

export enum SnapshotDateSuggestionType {
    CertificationStandardInitialDate = 1,
    ApplicationDate = 2,
    InspectionDate = 3,
}

export type SnapshotDateSuggestion =
    {
        type: SnapshotDateSuggestionType
        date: Date
        default: boolean
    }

export type SnapshotDateHints = { snapshotDateSuggestions: SnapshotDateSuggestion[] }

export type ActivityUnitViewModel =
    {
        applicationActivityUnitId: number
        persistentActivityUnitId: number
        activityUnitStatusId: ActivityUnitStatusId
        isCorrectable: boolean
        isCorrection: boolean
        snapshotStartDate: Date
        snapshotEndDate?: Date
        communityId: number
        addressLine1?: string
        postalCode?: string
        toponym?: string
        units?: number
        squareMeters?: number
        areaStremmata?: number
        activityUnitSpecificKindId?: ActivityUnitSpecificKindId
        weightKg?: number
        cbm?: number
        unitIdentifier?: string
        note?: string
        ownerFirstName?: string
        ownerLastName?: string
        unitCertificationStandards: CertificationState[]
        fragments: ActivityUnitFragmentViewModel[]
        programmes: ProgrammesInfoViewModel[]
        isPropagatingMaterial?: boolean
        isSubcontractorOnlyLocation?: boolean
    }

export type ActivityUnitListingViewModel =
    {
        applicationActivityUnitId: number
        persistentActivityUnitId: number
        snapshotStartDate: Date
        snapshotEndDate?: Date
        regionUnitName: string
        municipalityName: string
        communityName: string
        addressLine1?: string
        toponym?: string
        unitCertificationStandards: CertificationStateView[]
        unitIdentifier?: string
        fragments: string[]
        owned: boolean
        activityUnitStatusId: ActivityUnitStatusId
        isPropagatingMaterial?: boolean
        isSubcontractorOnlyLocation?: boolean
        squareMeters?: number
        areaStremmata?: number
        activityUnitSpecificKindId?: ActivityUnitSpecificKindId
        sourceId?: number
        isCorrection: boolean
    }

export type ActivityUnitAdded = { applicationActivityUnitId: number }

export type StoredItemBindingModel =
    {
        activityCategoryId: number
    }

type ApplicationWarehouseModelBase =
    {
        communityId: number
        toponym: string
        unitIdentifier?: string
        squareMeters?: number
        storageType?: string
        ownerFirstName?: string
        ownerLastName?: string
        note?: string
        storedItems: StoredItemBindingModel[]
    }

export type ApplicationWarehouseViewModel =
    ApplicationWarehouseModelBase & {
        applicationWarehouseId: number
        regionId: number
        regionUnitId: number
        municipalityId: number
    }

export type ApplicationWarehouseBindingModel = ApplicationWarehouseModelBase & { applicationWarehouseId?: number }

export const EMPTY_APPLICATION_WAREHOUSE: ApplicationWarehouseViewModel =
{
    applicationWarehouseId: 0,
    communityId: 0,
    municipalityId: 0,
    regionId: 0,
    regionUnitId: 0,
    toponym: '',
    storedItems: []
}

export type ApplicationWarehouseListingView =
    {
        applicationWarehouseId: number
        regionUnitName: string
        municipalityName: string
        communityName: string
        toponym: string
        unitIdentifier: string
        owned: boolean
    }

export type ActivityUnitRecordListingViewModel =
    {
        applicationActivityUnitId: number
    } & ActivityUnitRecordListingViewModelBase

export type Difference =
    {
        propertyPath: string[]
        originalValue: any
        currentValue: any
        differenceType: DiffType
    }

export type Changeset =
    {
        changes: IdDisplayPair<TagId>[]
        locked: IdDisplayPair<TagId>[]
        activeApplications: { applicationId: number, username: string }[]
        basicActivityGroupIsLocked: { [GroupId in BasicActivityGroupId]?: boolean }
    }

export type AgreementBindingModel =
    {
        startDate: Date
        endDate?: Date
        certificationStandardId: number
        file?: File
        markAsPending: boolean
    }

export type BasicActivityGroupDetailed =
    {
        basicActivityGroupId: BasicActivityGroupId
        name: string
        certificationStandards: {
            certificationStandardId: number
            certificationStandard: string
            numberOfActivityUnits: number
        }[]
    }

export type ApplicationOtherActivityBindingModel =
    {
        certificationOrganizationId: number,
        certificationStandardId: number,
        expirationDate?: Date
    }

export const EMPTY_APPLICATION_OTHER_ACTIVITY_BINDING_MODEL: ApplicationOtherActivityBindingModel = {
    certificationOrganizationId: 0,
    certificationStandardId: 0
}

export type ApplicationOtherActivityInformationPersonBindingModel =
    {
        firstName: string
        lastName: string
        contactInformation: Partial<ContactInformation>
    }

export const EMPTY_APPLICATION_OTHER_ACTIVITY_INFORMATION_PERSON_BINDING_MODEL: ApplicationOtherActivityInformationPersonBindingModel = {
    firstName: "",
    lastName: "",
    contactInformation: {}
}

export type ApplicationOtherActivityInformationModel = ApplicationOtherActivityInformationPersonBindingModel & { applicationOtherActivityIds: number[] }

export type ApplicationOtherActivitiesListing =
    {
        certificationStandardName: string,
        organizationCertificationName: string,
        expirationDate: Date,
        applicationOtherActivityId: number
    }

export type ApplicationOtherActivityInformationListing =
    {
        applicationOtherActivityInformationId: number,
        certificationStandardName: string,
        organizationCertificationName: string,
        firstName: string,
        lastName: string,
        contactInformation: Partial<ContactInformation>
    }

export type BatchUpsertActivityUnitsResponse =
    {
        units: UnitLine[]
        errors: string[]
        warnings?: string[]
    }

export type UnitLine =
    {
        regionUnit: string
        community: string
        toponym: string
        unitIdentifier: string
        units?: number
        squareMeters: number
        areaStremmata: number
        activityUnitSpecificKindId?: ActivityUnitSpecificKindId
        certificationStandard?: string
        organicCertificationCategory: string
        initialDate: Date
        complianceDate: Date
        note?: string
        fragments: FragmentLine[]
        persistentId?: number
        snapshotStartDate?: Date
        archiveDate?: Date
        isCorrection?: boolean
    }

export type FragmentLine =
    {
        activityCategory: string,
        attributes: string[],
        products: string[],
        variants: string[],
        units?: number,
        squareMeters?: number
        areaStremmata?: number
    }


export type UnitIdentifierCheckResult =
    {
        checkResult: CheckResultId
    }

export type CustomerIdAllocation =
    {
        customerId: number
        documentId: number
    }

export type ApplicationCheck = { isValid: boolean, failures: string[] }



export type ApplicationConvertResult =
    {
        customerId: number,
        isNewCustomer: boolean,
        shouldEditVirtualAgreements: boolean
    }

export type SuggestedVirtualAgreementsToChange = {
    virtualAgreementsToChange: SuggestedModel[],
    customerAgreementId: number,
    couldCreateMixedAgreement: boolean
}

export type SuggestedModel =
    {
        virtualAgreementBindingModel: VirtualAgreement,
        actionId: SuggestedModelActionId
    }


export class ApplicationsApi {
    constructor(private readonly api: ApiClient) { }

    public upsertApplication = (c: Application) =>
        this.api.onUnexpected(
            this.api.execute<Application>(200, 'PUT', `api/tenants/${this.api.tenantId}/applications/new`, undefined, c)
            , "Could not submit changes", EMPTY_APPLICATION)

    public getDetails = (id: number) =>
        this.api.onUnexpected(
            this.api.execute<ApplicationDetails>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${id}/details`)
            , "Could not load application details", null, false, true)

    public getApplication = (id: number) =>
        this.api.onUnexpected(
            this.api.execute<Application>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${id}`)
            , "Could not load application details", EMPTY_APPLICATION)

    public allocateCustomerId = (applicationId: number, certificationStandardId: number) =>
        this.api.onUnexpected(
            this.api.execute<CustomerIdAllocation>(200, 'PUT', `api/tenants/${this.api.tenantId}/applications/${applicationId}/allocate`, undefined, { certificationStandardId })
            , "Could not load allocate customer Id", null)

    public convert = (applicationId: number, agreements: AgreementBindingModel[], skipDiffCalculation?: boolean) => {
        const formData = new FormData()
        agreements.forEach((ag, i) => {
            formData.append(`data[${i}].startDate`, toISOStringWIthTimezone(ag.startDate))
            if (ag.file) formData.append(`data[${i}].file`, ag.file)
            formData.append(`data[${i}].certificationStandardId`, ag.certificationStandardId.toString())
            if (ag.endDate) formData.append(`data[${i}].endDate`, toISOStringWIthTimezone(ag.endDate))
            formData.append(`data[${i}].markAsPending`, ag.markAsPending.toString())
        })

        return this.api.onUnexpected(
            this.api.execute<ApplicationConvertResult>(200, 'POST', `api/tenants/${this.api.tenantId}/applications/${applicationId}/convert`, { skipDiffCalculation }, formData, 4 * 60000),
            "Could not merge application to customer",
            null)
    }

    public getSuggestedVirtualAgreements = (applicationId: number) =>
        getResource<SuggestedVirtualAgreementsToChange | undefined>(this.api, `api/tenants/${this.api.tenantId}/applications/${applicationId}/suggest-vas`, undefined)


    public getApplicationCheck = (applicationId: number) =>
        this.api.onUnexpected(
            this.api.execute<ApplicationCheck>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/check`),
            "Could not load application check", null)

    public getDiff = (applicationId: number) =>
        this.api.onUnexpected(
            this.api.execute<Difference[]>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/diff`),
            "Could not calculate diff",
            [] as Difference[])

    public getChangeset = (applicationId: number) =>
        this.api.onUnexpected(
            this.api.execute<Changeset>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/changeset`),
            "Could not get changeset",
            null)

    public upsert = (c: ApplicationCustomerDetailsBindingModel, applicationId: number) =>
        this.api.onUnexpected(
            this.api.execute<ApplicationUpdated>(200, 'PUT', `api/tenants/${this.api.tenantId}/applications/${applicationId}`, undefined, c)
            , "Could not submit changes", null)

    public delete = (id: number) =>
        this.api.onUnexpected(
            this.api.execute<{}>(204, 'DELETE', `api/tenants/${this.api.tenantId}/applications/${id}`)
            , "Δεν είναι δυνατή η διαγραφή της αίτησης", null)

    public get = (pagination: PaginationRequest, filters: FilterWithValue[], sorting: SortCriterionWithValue[]) => {
        const queryParams = combineQueryParams(pagination, mapFiltersToQueryParamsObject(filters), mapSortingToQueryParamObject(sorting))
        return this.getFromHref(`api/tenants/${this.api.tenantId}/applications?${queryParams.toString()}`)
    }
    public getExcel = (filters: FilterWithValue[], sorting: SortCriterionWithValue[]) => {
        const queryParams = combineQueryParams(mapFiltersToQueryParamsObject(filters), mapSortingToQueryParamObject(sorting))
        return this.api.onUnexpected(
            this.api.execute<BlobResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/export`, queryParams, undefined, ExportExcelTimeout, undefined, undefined, true),
            "Could not export given search to Excel",
            null)
    }
    public getFromHref = (href: string) =>
        this.api.onUnexpected(
            this.api.execute<Paginated<ApplicationListingViewModel>>(200, 'GET', href)
            , "Could not load applications page", fakePaginated([]))

    public describe = () =>
        this.api.onUnexpected(
            this.api.execute<ApiDescription>(200, 'OPTIONS', `api/tenants/${this.api.tenantId}/applications`)
            , "Could not load api description", EMPTY_API_DESCRIPTION)

    public assignConsultant = (applicationId: number, consultantId: number, certificationStandardId: number, isSecondaryAssignment: boolean | undefined) =>
        this.api.onUnexpected(
            this.api.execute<{}>(204, 'PUT', `api/tenants/${this.api.tenantId}/applications/${applicationId}/consultants`, undefined, { consultantId: consultantId, isSecondaryAssignment, certificationStandardId })
            , "Could not assign consultant", null)

    public getAssignedConsultants = (applicationId: number) =>
        this.api.onUnexpected(
            this.api.execute<ApplicantConsultantAssignmentViewModel[]>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/consultants`),
            "Could not load assigned consultants",
            [])

    public deallocateConsultant = (applicationId: number, consultantAssignmentId: number) =>
        this.api.onUnexpected(
            this.api.execute<{}>(204, 'DELETE', `api/tenants/${this.api.tenantId}/applications/${applicationId}/consultants/${consultantAssignmentId}`)
            , "Could not remove consultant", null)

    public getActivityGroups = (applicationId: number) =>
        this.api.onUnexpected(
            this.api.execute<BasicActivityGroupView[]>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups`),
            "Could not load activity groups", [] as BasicActivityGroupView[])

    public getActivityGroupsDetailed = (applicationId: number) =>
        this.api.onUnexpected(
            this.api.execute<BasicActivityGroupDetailed[]>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/detailed`),
            "Could not load activity groups (detailed)", [] as BasicActivityGroupDetailed[])

    public upsertActivityGroup = (applicationId: number, activityCategoryId: number) =>
        this.api.onUnexpected(
            this.api.execute<ActivityGroupAdded>(200, 'PUT', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${activityCategoryId}`),
            "Could not add activity group",
            null)

    public deleteActivityGroup = (applicationId: number, activityCategoryId: number) =>
        this.api.onUnexpected(
            this.api.execute<{}>(204, 'DELETE', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${activityCategoryId}`),
            "Could not delete activity group",
            null)

    public getSubcontractors = (applicationId: number) =>
        this.api.onUnexpected(
            this.api.execute<SubcontractorRelationshipListingViewModel[]>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/subcontractors`),
            "Could not load subcontractors",
            [] as SubcontractorRelationshipListingViewModel[])

    public getSubcontractor = (applicationId: number, entityRelationshipId: number) =>
        this.api.onUnexpected(
            this.api.execute<SubcontractorViewModel>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/subcontractors/${entityRelationshipId}`)
            , "Could not load subcontractor", null)

    public deleteSubcontractor = (applicationId: number, subcontractorId: number) =>
        this.api.onUnexpected(
            this.api.execute<{}>(204, 'DELETE', `api/tenants/${this.api.tenantId}/applications/${applicationId}/subcontractors/${subcontractorId}`)
            , "Could not delete the subcontractor", null)

    public upsertProperty = (applicationId: number, activityCategoryId: number, c: PropertyBindingModel) =>
        this.api.onUnexpected(
            this.api.execute<PropertyAdded>(200, 'PUT', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${activityCategoryId}/properties${c.applicationPropertyId ? `/${c.applicationPropertyId}` : ''}`, undefined, c, this.api.maxTimeoutMs)
            , "Could not update property", null)

    public getProperties = (applicationId: number, activityCategoryId: number) =>
        this.api.onUnexpected(
            this.api.execute<PropertyListingViewModel[]>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${activityCategoryId}/properties`),
            "Could not load properties",
            [] as PropertyListingViewModel[])

    public getProperty = (applicationId: number, activityCategoryId: number, propertyId: number) =>
        this.api.onUnexpected(
            this.api.execute<PropertyViewModel>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${activityCategoryId}/properties/${propertyId}`)
            , "Could not get property", null)

    public deleteProperty = (applicationId: number, activityCategoryId: number, propertyId: number) =>
        this.api.onUnexpected(
            this.api.execute<{}>(204, 'DELETE', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${activityCategoryId}/properties/${propertyId}`),
            "Could not delete property",
            null)

    public upsertActivityUnit = (applicationId: number, activityCategoryId: number, c: ActivityUnitViewModel) =>
        this.api.onUnexpected(
            this.api.execute<ActivityUnitAdded>(200, 'PUT', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${activityCategoryId}/activity-units${c.applicationActivityUnitId ? `/${c.applicationActivityUnitId}` : ''}`, undefined, c, this.api.maxTimeoutMs)
            , "Could not add activity unit", null)

    public setActivityUnitStatus = (applicationId: number, activityCategoryId: number, applicationActivityUnitId: number, snapshotEndDate: Date) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'POST', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${activityCategoryId}/activity-units/${applicationActivityUnitId}/archive`, undefined, { snapshotEndDate: snapshotEndDate }),
            "Δεν έγινε αλλαγή της κατάστασης της μονάδας", null)

    public batchSetProgramme = (applicationId: number, basicActivityGroupId: number, applicationActivityUnitIds: number[] | null, filters: FilterWithValue[] | null, selectedProgrammeId: number) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'POST', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${basicActivityGroupId}/activity-units/programmes`, mapFiltersToQueryParamsObject(filters ?? []), { applicationActivityUnitIds: applicationActivityUnitIds, selectedProgrammeId: selectedProgrammeId }),
            "Δεν έγινε αλλαγή της κατάστασης της μονάδας", null)

    public batchDateCorrection = (applicationId: number, basicActivityGroupId: number, persistentActivityUnitIds: number[] | null, filters: FilterWithValue[] | null, includeComplianceDate: boolean, certificationStandardId: number, initialDate: Date) =>
        this.api.onUnexpected(
            this.api.execute<{ count: number }>(200, 'PUT', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${basicActivityGroupId}/activity-units/date-correction`, mapFiltersToQueryParamsObject(filters ?? []), { includeComplianceDate, certificationStandardId, initialDate, persistentActivityUnitIds }),
            "Δεν έγινε αλλαγή της ημερομηνίας ένταξης", null)

    public batchSnapshotDateCorrection = (applicationId: number, basicActivityGroupId: number, applicationActivityUnitIds: number[] | null, filters: FilterWithValue[] | null, snapshotStartDate: Date) =>
        putResource(this.api, `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${basicActivityGroupId}/activity-units/snapshot-date-correction`, { applicationActivityUnitIds, snapshotStartDate }, { queryParams: mapFiltersToQueryParamsObject(filters ?? []) })

    public getActivityUnits = (applicationId: number, activityCategoryId: number, pagination: PaginationRequest, filters: FilterWithValue[], sorting: SortCriterionWithValue[]) => {
        const queryParams = combineQueryParams(pagination, mapFiltersToQueryParamsObject(filters), mapSortingToQueryParamObject(sorting))
        return this.getActivityUnitsFromHref(`api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${activityCategoryId}/activity-units?${queryParams.toString()}`)
    }

    public getActivityUnitsFromHref = (href: string) =>
        this.api.onUnexpected(
            this.api.execute<Paginated<ActivityUnitListingViewModel>>(200, 'GET', href),
            "Could not load activity units list",
            fakePaginated([] as ActivityUnitListingViewModel[]))

    public getActivityUnitsExcel = (filters: FilterWithValue[], sorting: SortCriterionWithValue[], applicationId: number, basicActivityGroupId: number) => {
        const queryParams = combineQueryParams(mapFiltersToQueryParamsObject(filters), mapSortingToQueryParamObject(sorting))
        return this.api.onUnexpected(
            this.api.execute<BlobResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${basicActivityGroupId}/activity-units/export`, queryParams, undefined, ExportExcelTimeout, undefined, undefined, true),
            "Could not export given search to Excel",
            null)
    }

    public getActivityUnitsEditExcel = (filters: FilterWithValue[], sorting: SortCriterionWithValue[], applicationId: number, basicActivityGroupId: number) => {
        const queryParams = combineQueryParams(mapFiltersToQueryParamsObject(filters), mapSortingToQueryParamObject(sorting))
        return this.api.onUnexpected(
            this.api.execute<BlobResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${basicActivityGroupId}/activity-units/edit-export`, queryParams, undefined, undefined, undefined, undefined, true),
            "Could not export given search to Excel",
            null)
    }

    public describeActivityUnits = (applicationId: number, activityCategoryId: number) =>
        this.api.onUnexpected(
            this.api.execute<ApiDescription>(200, 'OPTIONS', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${activityCategoryId}/activity-units`)
            , "Could not load api description", EMPTY_API_DESCRIPTION)

    public getActivityUnit = (applicationId: number, activityCategoryId: number, activityUnitId: number) =>
        this.api.onUnexpected(
            this.api.execute<ActivityUnitViewModel>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${activityCategoryId}/activity-units/${activityUnitId}`)
            , "Could not load activity unit", EMPTY_APPLICATION_ACTIVITY_UNIT_VIEW)

    public batchDeleteActivityUnits = (applicationId: number, basicActivityGroupId: number, applicationActivityUnitIds: number[] | null, filters: FilterWithValue[] | null) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'DELETE', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${basicActivityGroupId}/activity-units/batch`, mapFiltersToQueryParamsObject(filters ?? []), { applicationActivityUnitIds: applicationActivityUnitIds })
            , "Could delete activity units", null)

    public batchUnarchiveActivityUnits = (applicationId: number, basicActivityGroupId: number, applicationActivityUnitIds: number[] | null, filters: FilterWithValue[] | null) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'POST', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${basicActivityGroupId}/activity-units/unarchive`, mapFiltersToQueryParamsObject(filters ?? []), { applicationActivityUnitIds: applicationActivityUnitIds })
            , "Δεν είναι δυνατή η αναιρεση αρχειοθέτησης των μονάδων", null)

    public getUnitHint = (applicationId: number, activityCategoryId: number) =>
        this.api.execute<UnitHint>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${activityCategoryId}/activity-units/hint`, undefined, undefined, 2500) // TODO

    public getSnapshotDateHints = (applicationId: number, basicActivityGroupId: number) =>
        this.api.onUnexpected(
            this.api.execute<SnapshotDateHints>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${basicActivityGroupId}/activity-units/snapshot-hint`)
            , "Δεν μπόρεσαν να φορτωθούν οι προτεινόμενες ημερομηνίες", { snapshotDateSuggestions: [] } as SnapshotDateHints)

    public getUnitIdentifierCheck = (applicationId: number, basicActivityGroupId: number, unitIdentifier: string, persistentActivityUnitId: number | undefined) =>
        this.api.onUnexpected(
            this.api.execute<UnitIdentifierCheckResult>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${basicActivityGroupId}/activity-units/identifier-check`, { persistentActivityUnitId: persistentActivityUnitId, unitIdentifier: unitIdentifier })
            , "Δεν ήταν δυνατόν να γίνει ο έλεγχος Χαρτογραφικού Υπόβαθρου", null)

    public batchUpsertUnits = async (applicationId: number, basicActivityGroupId: BasicActivityGroupId, model: { file: File, isDryRun: boolean, sheetName: string, isEdit: boolean }): Promise<BatchUpsertActivityUnitsResponse | {} | null> => {
        const formData = new FormData()
        formData.append('file', model.file)
        formData.append('isDryRun', model.isDryRun.toString())
        formData.append('sheetName', model.sheetName)
        formData.append('isEdit', model.isEdit.toString())
        const response = await this.api.execute<BatchUpsertActivityUnitsResponse>(204, 'POST', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${basicActivityGroupId}/activity-units/batch`, undefined, formData, this.api.maxTimeoutMs)
        switch (response.kind) {
            case 'success': return {}
            case 'unexpected':
                if (response.status === 200 || response.status === 400)
                    return response.data as BatchUpsertActivityUnitsResponse
                else
                    return await this.api.onUnexpected(Promise.resolve(response), "Could not batch insert activity units", null)
        }
    }

    public upsertWarehouse = (applicationId: number, activityCategoryId: number, c: ApplicationWarehouseBindingModel) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'PUT', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${activityCategoryId}/warehouses${c.applicationWarehouseId ? `/${c.applicationWarehouseId}` : ''}`, undefined, c)
            , "Could not add warehouse", null)

    public getWarehouses = (applicationId: number, activityCategoryId: number) =>
        this.api.onUnexpected(
            this.api.execute<ApplicationWarehouseListingView[]>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${activityCategoryId}/warehouses`),
            "Could not load warehouses",
            [] as ApplicationWarehouseListingView[])

    public getWarehouse = (applicationId: number, activityCategoryId: number, warehouseId: number) =>
        this.api.onUnexpected(
            this.api.execute<ApplicationWarehouseViewModel>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${activityCategoryId}/warehouses/${warehouseId}`)
            , "Could not load warehouse", EMPTY_APPLICATION_WAREHOUSE)

    public deleteWarehouse = (applicationId: number, activityCategoryId: number, warehouseId: number) =>
        this.api.onUnexpected(
            this.api.execute<{}>(204, 'DELETE', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${activityCategoryId}/warehouses/${warehouseId}`)
            , "Could not delete warehouse", null)

    public upsertRecords = (applicationId: number, basicActivityGroupId: number, activityUnitId: number, snapshotEndDate: Date, note?: string, ...ancestorIds: number[]) =>
        this.api.onUnexpected(
            this.api.execute<void>(204, 'PUT', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${basicActivityGroupId}/activity-units/${activityUnitId}/records`, undefined, { snapshotEndDate: snapshotEndDate, ancestorActivityUnitIds: ancestorIds, note: note })
            , "Could not link to records", undefined)

    public deleteRecord = (applicationId: number, activityCategoryId: number, activityUnitId: number, ancestorId: number) =>
        this.api.execute<{}>(204, 'PUT', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${activityCategoryId}/activity-units/${activityUnitId}/records/${ancestorId}`)

    public getActivityUnitRecords = (applicationId: number, activityCategoryId: number, activityUnitId: number) =>
        this.api.onUnexpected(
            this.api.execute<ActivityUnitRecordListingViewModel[]>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${activityCategoryId}/activity-units/${activityUnitId}/records`)
            , "Could not load records", [] as ActivityUnitRecordListingViewModel[])

    public getActivityUnitRecordIds = (applicationId: number, activityCategoryId: number, activityUnitId: number) =>
        this.api.onUnexpected(
            this.api.execute<number[]>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${activityCategoryId}/activity-units/${activityUnitId}/record-ids`)
            , "Could not load records", [] as number[])

    public getProgrammes = (applicationId: number) =>
        this.api.onUnexpected(
            this.api.execute<ProgrammeViewModel[]>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/programmes`),
            "Could not create get programmes", [] as ProgrammeViewModel[])

    public addProgramme = (applicationId: number, programmeId: number) =>
        this.api.onUnexpected(
            this.api.execute<{}>(204, 'PUT', `api/tenants/${this.api.tenantId}/applications/${applicationId}/programmes`, undefined, { programmeId: programmeId }),
            "Could not add programme", undefined)

    public deleteProgramme = (applicationId: number, programmeId: number) =>
        this.api.onUnexpected(
            this.api.execute<{}>(204, 'DELETE', `api/tenants/${this.api.tenantId}/applications/${applicationId}/programmes/${programmeId}`),
            "Could not delete programme", null)

    public createOtherActivity = (c: ApplicationOtherActivityBindingModel, applicationId: number) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'POST', `api/tenants/${this.api.tenantId}/applications/${applicationId}/other-activities`, undefined, c),
            "Δεν δημιουργήθηκε άλλη δραστηριότητα", null)

    public createOtherActivityInformation = (applicationId: number, model: ApplicationOtherActivityInformationModel) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'POST', `api/tenants/${this.api.tenantId}/applications/${applicationId}/other-activities/information`, undefined, model),
            "Could not create other activity infos", null)

    public getOtherActivities = (applicationId: number) =>
        this.api.onUnexpected(
            this.api.execute<ApplicationOtherActivitiesListing[]>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/other-activities`),
            "Δεν φορτώθηκαν οι άλλες δραστηριότητες", [] as ApplicationOtherActivitiesListing[])

    public getOtherActivityInformations = (applicationId: number) =>
        this.api.onUnexpected(
            this.api.execute<ApplicationOtherActivityInformationListing[]>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/other-activities/information`),
            "Δεν φορτώθηκαν οι άλλες δραστηριότητες", [] as ApplicationOtherActivityInformationListing[])

    public deleteOtherActivity = (applicationId: number, applicationOtherActivityId: number) =>
        this.api.onUnexpected(
            this.api.execute<{}>(204, 'DELETE', `api/tenants/${this.api.tenantId}/applications/${applicationId}/other-activities/${applicationOtherActivityId}`),
            "Δεν έγινε η διαγραφή της άλλης δραστηριότητας", null)

    public deleteOtherActivityInformation = (applicationId: number, applicationOtherActivityInformationId: number) =>
        this.api.onUnexpected(
            this.api.execute<{}>(204, 'DELETE', `api/tenants/${this.api.tenantId}/applications/${applicationId}/other-activities/information/${applicationOtherActivityInformationId}`),
            "Δεν έγινε η διαγραφή των πληροφοριων άλλης δραστηριότητας", null)

    public getCustomerView = (applicationId: number) =>
        this.api.onUnexpected(
            this.api.execute<CustomerViewModel>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/customer-view`),
            "Δεν είναι δυνατη η εμφάνισης της εικόνας του πελάτη", null)

    public getCustomerViewExcel = (applicationId: number) =>
        this.api.onUnexpected(
            this.api.execute<BlobResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/applications/${applicationId}/customer-view/export`, undefined, undefined, ExportExcelTimeout, undefined, undefined, true),
            "Δεν είναι δυνατή η εμφάνιση της εικόνας πελάτη σε excel",
            null)

    public batchAssignUser = (applicationIds: number[] | null, filters: FilterWithValue[] | null, userId: number) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'POST', `api/tenants/${this.api.tenantId}/applications/batch-assign-user`, mapFiltersToQueryParamsObject(filters ?? []), { applicationIds: applicationIds, userId: userId }),
            "Δεν είναι δυνατή η μεταβολή των αιτησεων", null)

    public changeApplicationDate = (applicationId: number, date: Date) =>
        putResource(this.api, `api/tenants/${this.api.tenantId}/applications/${applicationId}/date`, { dateOfApplication: date }, { expected: 204 })

    public batchRestoreArchivedActivityUnits = (applicationId: number, basicActivityGroupId: number, applicationActivityUnitIds: number[] | null, filters: FilterWithValue[] | null, startDate: Date) =>
        postResource(this.api, `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${basicActivityGroupId}/activity-units/restore`, { applicationActivityUnitIds: applicationActivityUnitIds, startDate: startDate }, { queryParams: mapFiltersToQueryParamsObject(filters ?? []) })

    public getTagsInfo = () =>
        getResource<{ tags: ChangesInfo[] }>(this.api, `api/tenants/${this.api.tenantId}/applications/tags-info`, { tags: [] })

    public createAutomatedDateApplication = () =>
        postResource(this.api, `api/tenants/${this.api.tenantId}/applications/automated-data`, undefined, undefined)

    public changeUnitSpecificKindId = (applicationId: number, basicActivityGroupId: number, applicationActivityUnitId: number, unitKindId: ActivityUnitSpecificKindId) =>
        putResource(this.api, `api/tenants/${this.api.tenantId}/applications/${applicationId}/activity-groups/${basicActivityGroupId}/activity-units/${applicationActivityUnitId}/specific-kind`, { unitKindId })
}
