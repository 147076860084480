import { HrefOrExplicitRequest } from "../components/core/DataGrid/ReadonlyDataGrid"
import { AddressLikeBindingModel, AddressLikeFullModel } from "./Address"
import { ApiClient, BlobResponse } from "./ApiClient"
import { IdResult } from "./Common"
import { ContactInformation } from "./ContactInformation"
import { combineQueryParams, fakePaginated, FilterWithValue, getApiDescription, getHref, getPaginated, mapFiltersToQueryParamsObject, mapSortingToQueryParamObject, Paginated, SortCriterionWithValue } from "./Search"
import { ConsultantStatusId, ExportExcelTimeout } from "./WellKnowIds"

export type CustomersPerCertificationStandard = { certificationStandardId?: number, count: number }

export type ConsultantModel =
    {
        consultantId: number
        statusId: ConsultantStatusId
        consultancyName: string
        contactInformation: ContactInformation
        address?: AddressLikeFullModel
        taxRegistrationNumber?: string
        customersPerCertificationStandard?: CustomersPerCertificationStandard[]
        effectiveRegionUnits?: string[]
        people?: ConsultancyPerson[]
    }

type ConsultantAssignmentViewModelBase =
    {
        consultancyName: string
        middleName?: string
        consultantId: number
        isSecondaryAssignment?: boolean
        certificationStandard: string
        contactInformation: ContactInformation
    }

export type ApplicantConsultantAssignmentViewModel =
    {
        applicationConsultantAssignmentId: number
    } & ConsultantAssignmentViewModelBase

export type CustomerConsultantAssignmentViewModel =
    {
        customerConsultantAssignmentId: number
    } & ConsultantAssignmentViewModelBase

export type ConsultantBindingModel = {
    consultantId?: number
    statusId: ConsultantStatusId
    consultancyName: string
    taxRegistrationNumber?: string
    branches?: string[]
    people: ConsultancyPerson[]
    middleName?: string
    nationalIdentityNumber?: string
    address?: AddressLikeBindingModel
    note?: string
    contactInformation: Partial<ContactInformation>
    customersPerCertificationStandard?: CustomersPerCertificationStandard[]
    effectiveRegionUnits?: string[]
}

export type ConsultancyPerson =
    {
        consultancyPersonId?: number
        firstName: string
        lastName: string
        contactInformation?: Partial<ContactInformation>
    }

export const EMPTY_CONSULTANT: ConsultantBindingModel =
{
    consultancyName: '',
    statusId: ConsultantStatusId.Active,
    contactInformation: {},
    people: [],
}
export class ConsultantsApi {
    constructor(private readonly api: ApiClient) { }

    public get = (request: HrefOrExplicitRequest) =>
        getPaginated<ConsultantModel>(this.api, getHref(request, `api/tenants/${this.api.tenantId}/consultants`))

    public getApi = () =>
        getApiDescription(this.api, `api/tenants/${this.api.tenantId}/consultants`)

    public getFromHref = (href: string) =>
        this.api.onUnexpected(
            this.api.execute<Paginated<ConsultantModel>>(200, 'GET', href)
            , "Η λήψη της λίστας συμβούλων απέτυχε",
            fakePaginated([] as ConsultantModel[]))

    public upsert = (c: ConsultantBindingModel) =>
        this.api.onUnexpected(
            this.api.execute<IdResult>(200, 'PUT', `api/tenants/${this.api.tenantId}/consultants${c.consultantId ? `/${c.consultantId}` : ''}`, undefined, c),
            "Ο σύμβουλος δεν αποθηκεύτηκε", null)

    public getDetails = (consultantId: number) =>
        this.api.onUnexpected(
            this.api.execute<ConsultantBindingModel>(200, 'GET', `api/tenants/${this.api.tenantId}/consultants/${consultantId}`),
            "Η λήψη των στοιχείων του συμβούλου απέτυχε", EMPTY_CONSULTANT)

    public getExcel = (filters: FilterWithValue[], sorting: SortCriterionWithValue[]) => {
        const queryParams = combineQueryParams(mapFiltersToQueryParamsObject(filters), mapSortingToQueryParamObject(sorting))
        return this.api.onUnexpected(
            this.api.execute<BlobResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/consultants/export`, queryParams, undefined, ExportExcelTimeout, undefined, undefined, true),
            "Could not export given search to Excel",
            null)
    }
}