import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { el } from 'date-fns/locale'
import React, { StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { Auth } from './auth/Auth'
import { Login, Logout } from './components/Login/Login'
import { useUserInfoContext } from './components/core/Contexts/UserInfoContext'
import { InternationalizationContainer } from './components/core/Internationalization/InternationalizationContainer'
import './index.css'
import routes from './routes'
import { AppThemeProvider } from './theme'
import { maybeDisableReactDevTools } from './utils/utils'

const App = React.lazy(() => import(/* webpackChunkName: "app" */ './App'))
const Manual = React.lazy(() => import(/* webpackChunkName: "manual" */ './manual/core/Manual'))

const LoadApp = () => {
    const { userInfo } = useUserInfoContext()
    return <Suspense fallback={<Login state={{ kind: 'loginComplete', userInfo: userInfo }} />}>
        <BrowserRouter>
            <InternationalizationContainer>
                <Routes>
                    <Route path={routes.HOMEPAGE.catchAll} element={<App />} />
                    <Route path={routes.DOCUMENTATION.template} element={<Manual />} />
                    <Route path={routes.LOGOUT.template} element={<Logout />} />
                    <Route path={routes.ROOT.catchAll} element={<Navigate to={routes.HOMEPAGE.template} replace />} />
                </Routes>
            </InternationalizationContainer>
        </BrowserRouter>
    </Suspense>
}

const AppRoot = () =>
    <AppThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={el}>
            <Auth unauthenticated={state => <Login state={state} />}>
                <LoadApp />
            </Auth>
        </LocalizationProvider>
    </AppThemeProvider>

maybeDisableReactDevTools()

const container = document.getElementById('root')!
const root = createRoot(container)
root.render(<StrictMode ><AppRoot /></StrictMode>)