import { LogLevel, PublicClientApplication, SilentRequest } from "@azure/msal-browser"
import React, { useEffect, useState } from "react"
import { APIAPP_ID, ENVIRONMENT, Environment, TENANT_ID, WEBAPP_ID } from "../ConfigurationInjection"
import { ApiClient } from "../api/ApiClient"
import { UserInformation } from "../api/User"
import { SupportedLanguage, TenantId } from "../api/WellKnowIds"
import { UserInfoContextProvider } from "../components/core/Contexts/UserInfoContext"
import { caseNever } from "../utils/never"

const msal = new PublicClientApplication({
    auth: {
        authority: `https://login.microsoftonline.com/${TENANT_ID}`,
        clientId: WEBAPP_ID,
        redirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            logLevel: ENVIRONMENT === Environment.Production ? LogLevel.Warning : LogLevel.Verbose,
            piiLoggingEnabled: ENVIRONMENT !== Environment.Production,
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (ENVIRONMENT === Environment.Production && containsPii) return
                switch (level) {
                    case LogLevel.Error: return console.error(`[MSAL] ${message}`)
                    case LogLevel.Info: return console.log(`[MSAL] ${message}`)
                    case LogLevel.Verbose: return console.debug(`[MSAL] ${message}`)
                    case LogLevel.Warning: return console.warn(`[MSAL] ${message}`)
                    default: return caseNever(level)
                }
            }
        }
    }
})

const scopes = [
    'openid',
    `api://${APIAPP_ID}/FullAccess`,
]

export const maybeLogin = async () => {
    const response = await msal.handleRedirectPromise()
    if (response === null || msal.getAllAccounts().length === 0)
        msal.loginRedirect({ scopes: scopes, extraQueryParameters: { domain_hint: 'organizations' } })
    else
        return true
}

export const logout = async () => {
    msal.logout()
}

export const getToken = async () => {
    const accessTokenRequest: SilentRequest = { account: msal.getAllAccounts()[0], scopes: scopes }
    try {
        const rs = await msal.acquireTokenSilent(accessTokenRequest)
        return rs.accessToken
    }
    catch (e: any) {
        if (e?.errorMessage?.indexOf("interaction_required") !== -1) {
            try {
                const token = await msal.acquireTokenPopup(accessTokenRequest)
                return token.accessToken
            }
            catch (e) {
                console.error(e)
                throw e
            }
        }
        console.error(e)
        throw e
    }
}

export type LoginState =
    | { kind: 'unauthenticated' }
    | { kind: 'loggingIn' }
    | { kind: 'authenticated', retry: number }
    | { kind: 'loginComplete', userInfo: UserInformation }

export const Auth: React.FC<React.PropsWithChildren<{ unauthenticated: (state: LoginState) => React.ReactNode }>> = props => {
    const [loginState, setLoginState] = useState<LoginState>({ kind: 'unauthenticated' })

    useEffect(() => {
        (async () => {
            switch (loginState.kind) {
                case 'unauthenticated':
                    setLoginState({ kind: 'loggingIn' })

                    let isAlreadyLoggedIn = false
                    if (msal.getAllAccounts().length > 0) {
                        try {
                            await getToken()
                            isAlreadyLoggedIn = true
                        }
                        catch { }
                    }

                    if (isAlreadyLoggedIn) {
                        setLoginState({ kind: 'authenticated', retry: 0 })
                    }
                    else {
                        try {
                            if (await maybeLogin())
                                setLoginState({ kind: 'authenticated', retry: 0 })
                        }
                        catch (e) {
                            console.error(e)
                            setLoginState({ kind: 'unauthenticated' })
                        }
                    }

                    break
                case 'loggingIn':
                case 'authenticated':
                case 'loginComplete':
                    return
                default: return caseNever(loginState)
            }
        })()
    }, [])

    useEffect(() => {
        if (loginState.kind === 'authenticated') {
            const client = new ApiClient(TenantId.Greece, SupportedLanguage.Greek)
            const timeout = 5000
            client.users.getCurrentSilent(timeout)
                .then(user => {
                    if (user)
                        setLoginState({ kind: 'loginComplete', userInfo: user })
                    else
                        setTimeout(() => setLoginState(st => {
                            if (st.kind === 'authenticated')
                                return { ...st, retry: st.retry + 1 }
                            else
                                return st
                        }), Math.min(30000, 100 + timeout * loginState.retry))
                })
        }
    }, [loginState])

    return <>
        {loginState.kind === 'loginComplete'
            ? <UserInfoContextProvider value={loginState.userInfo}>{props.children}</UserInfoContextProvider>
            : props.unauthenticated(loginState)}
    </>
}