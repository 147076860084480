import { ApiClient } from "./ApiClient"
import { getResource } from './Search'

export type TaxOffice =
    {
        taxOfficeId: number
        name: string
    }

export class TaxOfficesApi {
    constructor(private readonly api: ApiClient) { }

    public get = () =>
        getResource<TaxOffice[]>(this.api, `api/tenants/${this.api.tenantId}/tax-offices`, [])
}