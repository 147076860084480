import { ApiClient } from "./ApiClient"
import { ApiDescription, combineQueryParams, EMPTY_API_DESCRIPTION, fakePaginated, FilterWithValue, mapFiltersToQueryParamsObject, mapSortingToQueryParamObject, Paginated, PaginationRequest, SortCriterionWithValue } from "./Search"
import { RegistrationStatusId } from "./WellKnowIds"

export type ExternalUsersModel =
    {
        userId: number
        name: string
        username: string
        taxRegistrationNumber: string
        registrationStatus?: string
        registrationStatusId?: RegistrationStatusId
        applicationId?: number
        customerId?: number
    }

export type ExternalUserCorrelation = {
    customerId?: number
    taxRegistrationNumber: string
}

export type ExternalUsersGuideLines = {
    guideLines?: string
    customerId?: number
    trn?: string
    registrationStatus?: string
}

export class ExternalUsersApi {
    constructor(private readonly api: ApiClient) { }

    public describe = () =>
        this.api.onUnexpected(
            this.api.execute<ApiDescription>(200, 'OPTIONS', `api/tenants/${this.api.tenantId}/external-users-management`)
            , "Could not load api description", EMPTY_API_DESCRIPTION)

    public get = (pagination: PaginationRequest, filters: FilterWithValue[], sorting: SortCriterionWithValue[]) => {
        const queryParams = combineQueryParams(pagination, mapFiltersToQueryParamsObject(filters), mapSortingToQueryParamObject(sorting))
        return this.getFromHref(`api/tenants/${this.api.tenantId}/external-users-management?${queryParams.toString()}`);
    }

    public getFromHref = (href: string) =>
        this.api.onUnexpected(
            this.api.execute<Paginated<ExternalUsersModel>>(200, 'GET', href)
            , "Η λήψη του υποσυστήματος πελατών απέτυχε",
            fakePaginated([] as ExternalUsersModel[]))

    public deleteUser = (userId: number) =>
        this.api.onUnexpected(
            this.api.execute<void>(204, 'DELETE', `api/tenants/${this.api.tenantId}/external-users-management/${userId}`),
            "Could not delete user", null)


    public updateCorrellation = (userId: number, customer?: ExternalUserCorrelation) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'PUT', `api/tenants/${this.api.tenantId}/external-users-management/${userId}`, undefined, customer),
            "Could not upsert correllation", null)

    public getGuideLines = (userId: number) =>
        this.api.onUnexpected(
            this.api.execute<ExternalUsersGuideLines>(200, 'GET', `api/tenants/${this.api.tenantId}/external-users-management/${userId}/guidelines`)
            , "Could not load guidelines", {})


}
