import { ApiClient } from "./ApiClient"
import { getResource, postResource } from "./Search"

export type ChatHistoryLine = { isSystem: boolean, text: string }
export type ChatAnswer = {
    text: string,
    usedCitationIds: number[],
    relevantCitations: Citation[],
    rawPrompt: string
    rawAnswer: string
}
export type Citation = {
    citationId: number,
    subject: string,
    date: Date,
    text: string,
    confidence: number
}

export class OperationsApi {
    constructor(private readonly api: ApiClient) { }

    public fixProgrammes = () =>
        postResource(this.api, `api/tenants/${this.api.tenantId}/operations/fix-programmes`, null, { expected: 200, timeout: this.api.maxTimeoutMs })

    public encodeIds = (model: { input: string, target: string, encode: boolean }) =>
        getResource<{ output: string[] }>(this.api, `api/tenants/${this.api.tenantId}/operations/encoding`, { output: [] }, { queryParams: model })

    public gptComplete = (history: ChatHistoryLine[], useDalle: boolean, useTranslation: boolean) =>
        postResource<{ history: ChatHistoryLine[] }, ChatAnswer>(this.api, `api/tenants/${this.api.tenantId}/operations/gpt/complete`, { history }, { queryParams: { useDalle, useTranslation }, expected: 200, timeout: this.api.maxTimeoutMs })
}