import { ApiClient } from "./ApiClient"

export type CertificationOrganizationViewModel =
    {
        certificationOrganizationId: number
        name: string
    }

export class CertificationOrganizationsApi {
    constructor(private readonly api: ApiClient) { }

    public get = (includeSelf?: boolean) =>
        this.api.onUnexpected(
            this.api.execute<CertificationOrganizationViewModel[]>(200, 'GET', `api/tenants/${this.api.tenantId}/certification-organizations?includeSelf=${includeSelf || false}`),
            "Could not load certification organizations",
            []
        )
}