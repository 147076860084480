import { ExitToApp } from '@mui/icons-material'
import { Box, Button, Grid, Link, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { LogoSvg } from '../../Logo'
import { LoginState, logout, maybeLogin } from '../../auth/Auth'

type StyleProps =
    {
        deg: number
    }

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
    container: {
        width: '100vw',
        height: '100vh',
        justifyItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        color: 'white',
        justifyContent: 'center',
    },
    logoWrapper: {
        maxWidth: 96,
        maxHeight: 96,
        borderRadius: "50%",
        animation: '0.9s $animT ease infinite alternate',
    },
    "@keyframes animT": {
        "0%": {
            boxShadow: '0 0px 5px rgba(255,255,255,.8)',
        },
        "100%": {
            boxShadow: '0 0px 23px rgba(255,255,255,.8)',
        },
    }
}))

export const Login: React.FC<{ state: LoginState }> = props => {
    const [degrees, setDegrees] = useState(0)

    const classes = useStyles({ deg: degrees })

    const [slowLogin, setSlowLogin] = useState(false)
    const [mailTo, setMailTo] = useState<string>()

    useEffect(() => {
        const handle = setTimeout(() => setDegrees(d => d >= 360 ? 0 : d + 1), 20)
        return () => clearTimeout(handle)
    }, [degrees])

    useEffect(() => {
        if (props.state.kind === 'authenticated') {
            const handle = setTimeout(() => setSlowLogin(true), 5000)
            return () => clearTimeout(handle)
        }
    }, [props.state])

    const handleLoginClick = (e: React.MouseEvent) => {
        e.preventDefault()
        maybeLogin()
    }

    useEffect(() => {
        setMailTo(["mail", "to", ':', 'support', '@', 'techyotic', '.', 'com'].join(''))
    }, [])

    return (
        <Grid sx={{ background: theme => `linear-gradient(${degrees}deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.dark} 100%)` }} container spacing={0} direction="column" alignItems="center" justifyContent="center" className={classes.container}>
            <Button size='large' variant='text' style={{ color: 'white', position: 'absolute', top: 9, right: 9 }} onClick={logout} endIcon={<ExitToApp />}>ΕΞΟΔΟΣ</Button>
            <div className={classes.wrapper}>
                <Box className={classes.logoWrapper}>
                    <LogoSvg
                        width={96}
                        height={96}
                        blinkDelaySeconds={2}
                        disableSproutAnimation={props.state.kind === 'loginComplete'} />
                </Box>
            </div>
            <div className={classes.wrapper}>
                {props.state.kind === 'unauthenticated'
                    ? <Typography variant='h6'>Δεν είστε συνδεδεμένος στο σύστημα. Για να συνδεθείτε πατήστε <Link color='inherit' href="#" onClick={handleLoginClick}><b>εδω</b></Link>.</Typography>
                    : props.state.kind !== 'loginComplete'
                        ? <Typography variant='h6'>Πραγματοποιείται είσοδος στο σύστημα</Typography>
                        : <Typography variant='h6'>Επιτυχής είσοδος. Περιμένετε όσο φορτώνουμε την εφαρμογή.</Typography>
                }
            </div>
            <div className={classes.wrapper}>
                {slowLogin &&
                    <>
                        <Typography>
                            Η είσοδος παίρνει παραπάνω χρόνο απο το συνηθισμένο. Αν το πρόβλημα παραμένει δοκιμάστε τα εξής:
                            <ul>
                                <li>Επιλέξτε <b>έξοδο</b> από πάνω δεξιά, κλείστε το περιηγητή ιστού και ξαναδοκιμάστε την είσοδο.</li>
                                <li>Ακολουθήστε την <Link target='_blank' href='https://www.youtube.com/watch?v=tGpKgXIZ-0Q' color='inherit'><b>διαδικασία καθαρισμού Cookies</b></Link>.</li>
                                <li>Αν το πρόβλημα παραμένει <Link href={mailTo} color='inherit'><b>επικοινωνήστε</b></Link> με τον διαχειριστή του συστήματος.</li>
                            </ul>
                        </Typography>
                    </>
                }
            </div>
        </Grid>
    )
}

export const Logout = () => {
    useEffect(() => {
        logout()
    }, [])

    return <div>
        Δεν είστε συνδεδεμένος στο σύστημα. Για να συνδεθείτε πατήστε <Link href="#" onClick={(e: React.MouseEvent) => { e.preventDefault(); maybeLogin() }}><b>εδω</b></Link>.
    </div>
}