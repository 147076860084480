import { ApiClient, BlobResponse } from "./ApiClient"
import { getResource, postResource } from "./Search"
import { BasicActivityGroupId } from "./WellKnowIds"

export type PricingComponentListing =
    {
        pricingComponentId: number
        description: string
        defaultAmount?: number
        isIrregular: boolean
    }

export type PriceList =
    {
        standards: PriceListStandard[]
    }

export type PriceListStandard =
    {
        certificationStandardId: number
        certificationStandard: string
        groups: PriceListGroup[]
    }

export type PriceListGroup =
    {
        basicActivityGroupId: BasicActivityGroupId
        basicActivityGroup: string
        activityClasses: PriceListClass[]
    }

export type PriceListClass =
    {
        pricingClassId: number
        pricingClass: string
        inputType: PriceListInputType
        description?: string
        units?: string
    }

export type CalculatorPriceList =
    {
        discounts?: {
            standardPercentage?: number
            groupPercentage?: { [key in BasicActivityGroupId]?: number | undefined }
        }
        classes: CalculatorPriceListClass[]
    }

export type CalculatorPriceListClass =
    {
        id: number
        decimalMeasure?: number
        intMeasure?: number
    }

export type CalculatorResult =
    {
        effectiveTotalNoVat?: number
        needsAgreement: boolean
    }

export enum PriceListInputType {
    AreaStremmata = 1,
    LivestockCount = 2,
    ProcessingUnitsCount = 3,
    BeehiveCount = 5,
    AquacultureFish = 6,
    AquacultureSeaweed = 7,
}

export type PricingAnalysisView =
    {
        perCertificationStandard: PerCertificationStandardView[]
        pricingInfo?: PricingInfo
        notes?: string
        hash: string
    }

export type PerCertificationStandardView =
    {
        certificationStandardId: number
        certificationStandard: string
        perGroup: PerGroupView[]
        pricingInfo?: PricingInfo
    }

export type PerGroupView =
    {
        basicActivityGroupId: BasicActivityGroupId
        basicActivityGroup: string
        perActivityClass: PerActivityClassView[]
        pricingInfo?: PricingInfo
    }

export type PerActivityClassView =
    {
        pricingClassId: number
        pricingClass: string
        activityInfo: ActivityInfoView
        pricingInfo?: PricingInfo
    }

export type ActivityInfoView =
    {
        squareMeters?: number
        areaStremmata?: number
        units?: number
        sameGroupUnitsCount: number
        isPropagatingMaterial?: boolean
        isSubcontractorOnlyLocation?: boolean
    }

export type PricingClass =
    {
        pricingClassId: number
        description: string
    }

export type PricingInfo =
    {
        specialCost?: number

        referenceTotalNoVat: number
        referenceTotalNoDiscount: number

        totalNoVat: number
        discountPercentage?: AppliedPercentageDiscount[]
        discountFlat?: AppliedFlatDiscount[]
        override?: PricingOverrideInfo

        effectiveDiscountPercentage?: number
        effectiveDiscountFlat?: number
        effectiveTotalNoDiscount: number
        effectiveTotalNoVat: number

        agreementOverride: boolean
    }

export type PricingOverrideInfo =
    {
        totalNoVat?: number
        discountPercentage?: AppliedPercentageDiscount[]
        discountFlat?: AppliedFlatDiscount[]
    }

export type AppliedPercentageDiscount =
    {
        discountId?: number
        percentage: number
        expiry?: DiscountExpiry
    }

export type DiscountExpiry = { date: Date, expiryId?: string }

export type DiscountWithDescription<T extends AppliedFlatDiscount | AppliedPercentageDiscount> =
    T & { description: string }

export type AppliedFlatDiscount =
    {
        discountId?: number
        flat: number
        expiry?: DiscountExpiry
    }

export type Discount =
    {
        discountId?: number
        isAutomated?: boolean
        description?: string
        percentage?: number
        flat?: number
        expiry?: DiscountExpiry
    }

export class PricingApi {
    constructor(private readonly api: ApiClient) { }

    public getCustomerBillingReport = () =>
        this.api.onUnexpected(
            this.api.execute<BlobResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/pricing/reports/customer-billing-export`, undefined, undefined, 0, undefined, undefined, true),
            "Could not export given search to Excel",
            null)

    public getCustomerBillingExtrasReport = () =>
        getResource<BlobResponse, null>(this.api, `api/tenants/${this.api.tenantId}/pricing/reports/customer-billing-extras-export`, null, { timeout: 0, blob: true })

    public getCustomerBillingPeriodReport = () =>
        getResource<BlobResponse, null>(this.api, `api/tenants/${this.api.tenantId}/pricing/reports/customer-billing-period-export`, null, { timeout: 0, blob: true })

    public priceAllCustomers = (count: number, blacklist: number[]) =>
        this.api.onUnexpected(
            this.api.execute<string[]>(200, 'PUT', `api/tenants/${this.api.tenantId}/pricing/customer/all/apply`, { count }, { blacklist }, 24 * 60 * 60 * 1000),
            "Could not price customers", [] as string[])

    public getPriceList = () =>
        getResource<PriceList, null>(this.api, `api/tenants/${this.api.tenantId}/pricing/price-list`, null)

    public calculateFromPriceListSilent = (cpl: CalculatorPriceList) =>
        postResource<CalculatorPriceList, CalculatorResult>(this.api, `api/tenants/${this.api.tenantId}/pricing/price-list`, cpl)

    public getPricingComponents = () =>
        this.api.onUnexpected(
            this.api.execute<PricingComponentListing[]>(200, 'GET', `api/tenants/${this.api.tenantId}/pricing/components`),
            "Could not load pricing components", [] as PricingComponentListing[])

    public getDiscounts = () =>
        this.api.onUnexpected(
            this.api.execute<Discount[]>(200, 'GET', `api/tenants/${this.api.tenantId}/pricing/discounts`),
            "Could not load discounts", [] as Discount[])

    public getCustomerPricingPreview = (props: { customerId?: number, applicationId?: number, customerBillingId?: number }, includeActiveBillingOverrides: boolean, overridden?: PricingAnalysisView) => {
        return this.api.onUnexpected(
            this.api.execute<PricingAnalysisView>(200, 'POST', `api/tenants/${this.api.tenantId}/pricing/customer/preview`, undefined, { applicationId: props.applicationId, customerId: props.customerId, customerBillingId: props.customerBillingId, overridden: overridden, includeActiveBillingOverrides }),
            "Could not load pricing preview", undefined)
    }

    public priceApplications = () =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'PUT', `api/tenants/${this.api.tenantId}/pricing/applications/apply`, undefined, undefined, 24 * 60 * 60 * 1000),
            "Could not price applications", undefined)

    public getPricingClasses = () =>
        getResource<PricingClass[]>(this.api, `api/tenants/${this.api.tenantId}/pricing/classes`, [] as PricingClass[])
}