import { IconButton, Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Check, FileCopyOutlined } from "@mui/icons-material";
import React, { useState } from "react";
import { useUserInfoContext } from "../components/core/Contexts/UserInfoContext";

export const CorrelationIdLabel = (props: { correlationId: string, status?: number }) => {
    const classes = useStyles();
    const [copySuccess, setCopySuccess] = useState(false);
    const { userInfo } = useUserInfoContext()

    const handleCopy = () => {
        const additionalInfo = `*Correlation ID:* \`${props.correlationId}\`
*Status:* ${props.status || '-'}
*Username:* \`${userInfo.username}\`
*URL:* ${window.location.href}
`;

        navigator.clipboard.writeText(additionalInfo).then(() => {
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 2000);
        });
    };

    return (
        <Typography>
            Αν χρειάζεται να αναφέρετε αυτό το συγκεκριμένο ζήτημα σε έναν διαχειριστή,
            παρακαλώ αντιγράψτε τον κωδικό του σφάλματος:
            <Tooltip title={copySuccess ? <span>Έγινε αντιγραφή στο πρόχειρο <Check fontSize="inherit" /> </span> : "Αντιγραφή στο πρόχειρο"} placement="top">
                <span>
                    <span className={classes.correlationId} onClick={handleCopy}>
                        {props.correlationId}
                    </span>
                    <IconButton size="small" onClick={handleCopy} className={classes.copyIcon}>
                        <FileCopyOutlined fontSize="small" />
                    </IconButton>
                </span>
            </Tooltip>
        </Typography>
    );
};

const useStyles = makeStyles((theme) => ({
    correlationId: {
        fontWeight: 'bold',
        marginLeft: theme.spacing(1),
        border: `1px dashed ${theme.palette.divider}`,
        padding: theme.spacing(0.5),
        borderRadius: theme.shape.borderRadius,
        cursor: 'pointer',
    },
    copyIcon: {
        marginLeft: theme.spacing(1),
    },
}));
