import { toISOStringWIthTimezone } from "../utils/utils"
import { CustomerAddress } from "./Address"
import { ApiClient, BlobResponse } from "./ApiClient"
import { ContactInformation } from "./ContactInformation"
import { EmployeeBenefitModelBase as EmployeeBenefitModel } from "./EmployeeBenefits"
import { JobSpecializationModel } from "./JobSpecialization"
import { ApiDescription, combineQueryParams, EMPTY_API_DESCRIPTION, fakePaginated, FilterWithValue, mapFiltersToQueryParamsObject, mapSortingToQueryParamObject, Paginated, PaginationRequest, SortCriterionWithValue } from "./Search"
import { ExportExcelTimeout, InspectorStatusId } from "./WellKnowIds"

type InspectorModelBase =
    {
        userId?: number
        firstName: string
        lastName: string
        middleName?: string
        taxRegistrationNumber?: string
        nationalIdentityNumber?: string
        address?: CustomerAddress
        mailingAddress?: CustomerAddress
        contactInformation?: Partial<ContactInformation>
        iban?: string
        taxOfficeId?: number
        employmentRelationshipId?: number
        statusName: string
        statusId: InspectorStatusId
        benefits: EmployeeBenefitModel[]
        actionAreas: ActionAreaModel[]
        jobSpecializations: JobSpecializationModel[]
        certificationStandards: string[]
        activityCategories: string[]
        minNumberOfInspections?: number
        maxNumberOfInspections?: number
        readonly numberOfReferrals: number
        readonly activationDate?: Date
        readonly deactivationDate?: Date

    }
export type DatesBindingModel =
    {
        activationDate?: Date
        deactivationDate?: Date
    }

export type ActionAreaModel =
    {
        regionUnitId: number
        municipalityIds: number[]
    }

export type InspectorViewModel = InspectorModelBase & { inspectorId: number }

export type InspectorBindingModel = InspectorModelBase & { inspectorId?: number }

export const EMPTY_INSPECTOR: InspectorBindingModel =
{
    firstName: '',
    lastName: '',
    contactInformation: {},
    benefits: [],
    actionAreas: [],
    jobSpecializations: [],
    activityCategories: [],
    certificationStandards: [],
    numberOfReferrals: 0,
    statusName: "",
    statusId: InspectorStatusId.Active
}

export type InspectorCreated = { inspectorId: number }

export type EmploymentRelationshipView =
    {
        employmentRelationshipId: number
        name: string
    }

export type InspectorListingViewModel =
    {
        inspectorId: number
        taxRegistrationNumber: string
        firstName: string
        lastName: string
        status: string
        actionAreas: string[]
        basicActivityGroups: string[]
        certificationStandards: string[]
    }

export type InspectorTrainingBindingModel =
    {
        basicActivityGroupId: number
        candidateStartDate: Date
        certificationStandardId: number
        inspectorId: number
    }

export type InspectorTrainingViewModel = InspectorTrainingBindingModel &
{
    statusId: number
    activationDate?: Date
    deactivationDate?: Date
    certificationStandardName: string
    basicActivityGroupName: string
}
export const EMPTY_INSPECTOR_TRAINING_MODEL: InspectorTrainingBindingModel =
{
    basicActivityGroupId: 0,
    candidateStartDate: new Date(),
    certificationStandardId: 0,
    inspectorId: 0
}

export type Statistics =
    {
        statisticsPerInspector: InspectorsStatisticsView[]
        monthsNames: string[]
    }

export type InspectorsStatisticsView =
    {
        inspectorNaming: string
        minInspections?: number
        maxInspections?: number
        totalInspectionsInYear: number
        totalCompletedInspectionsInYear: number
        totalActiveInspectionsInYear: number
        totalAcceptedInspectionsInYear: number
        totalInspectionsWithSampleInYear: number
        totalIndependentSampleInYear: number
        totalUnannouncedInspectionsInYear: number
        totalIrreguralInspectionsInYear: number
        averageInspectionsPerDay: number
        actualMaxInspectionsPerDay: number
        perMonthInfos: PerMonthInfo[]
    }

export type PerMonthInfo =
    {
        monthId: number
        totalCompletedInspections: number
        totalActiveInspections: number
        totalAcceptedInspections: number
    }

export type CandidateInspector =
    {
        inspectorId: number
        canAssignInspector: boolean
        canAllocateDate?: boolean
        isBlacklisted: boolean
    }

export class InspectorsApi {
    constructor(private readonly api: ApiClient) { }

    public get = (pagination: PaginationRequest, filters: FilterWithValue[], sorting: SortCriterionWithValue[]) => {
        const queryParams = combineQueryParams(pagination, mapFiltersToQueryParamsObject(filters), mapSortingToQueryParamObject(sorting))
        return this.getFromHref(`api/tenants/${this.api.tenantId}/inspectors?${queryParams.toString()}`);
    }

    public getFromHref = (href: string) =>
        this.api.onUnexpected(
            this.api.execute<Paginated<InspectorListingViewModel>>(200, 'GET', href)
            , "Could not load inspectors", fakePaginated([] as InspectorListingViewModel[]))

    public describe = () =>
        this.api.onUnexpected(
            this.api.execute<ApiDescription>(200, 'OPTIONS', `api/tenants/${this.api.tenantId}/inspectors`)
            , "Could not load api description", EMPTY_API_DESCRIPTION)

    public getDetails = (inspectorId: number) =>
        this.api.onUnexpected(
            this.api.execute<InspectorViewModel>(200, 'GET', `api/tenants/${this.api.tenantId}/inspectors/${inspectorId}`)
            , "Δεν είναι δυνατή η εμφάνιση του επιθεωρητή", null)

    public upsert = (c: InspectorBindingModel) =>
        this.api.onUnexpected(
            this.api.execute<InspectorCreated>(200, 'PUT', `api/tenants/${this.api.tenantId}/inspectors${c.inspectorId ? `/${c.inspectorId}` : ''}`, undefined, c)
            , "Δεν είναι δυνατή η μεταβολή της εικόνας του επιθεωρητή", null)

    public delete = (inspectorId: number) =>
        this.api.execute<{}>(204, 'DELETE', `api/tenants/${this.api.tenantId}/inspectors/${inspectorId}`)

    public upsertTraining = (c: InspectorTrainingBindingModel) =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'PUT', `api/tenants/${this.api.tenantId}/inspectors/${c.inspectorId}/inspector-trainings`, undefined, c),
            "Δεν πραγματοποιήθηκε η εισαγωγή εκπαίδευσης", null
        )

    public getInspectorTrainings = (inspectorId: number) =>
        this.api.onUnexpected(
            this.api.execute<InspectorTrainingViewModel[]>(200, 'GET', `api/tenants/${this.api.tenantId}/inspectors/${inspectorId}/inspector-trainings`),
            "Δεν πραγματοποιήθηκε η λήψη των εκπαιδεύσεων",
            [])

    public getEmploymentRelationships = () =>
        this.api.onUnexpected(
            this.api.execute<EmploymentRelationshipView[]>(200, 'GET', `api/tenants/${this.api.tenantId}/inspectors/employment-relationships`),
            "Could not load employment relationships",
            [])

    public activate = (inspectorId: number, c: Date, certificationStandardId?: number, basicActivityGroupId?: number) =>
        this.api.onUnexpected(
            this.api.execute<{}>(204, 'PUT', `api/tenants/${this.api.tenantId}/inspectors/${inspectorId}/status/activate`, undefined, { activationDate: c, certificationStandardId: certificationStandardId, basicActivityGroupId: basicActivityGroupId })
            , "Could not activate inspector", {})

    public deactivate = (inspectorId: number, c: Date, certificationStandardId?: number, basicActivityGroupId?: number) =>
        this.api.onUnexpected(
            this.api.execute<{}>(204, 'PUT', `api/tenants/${this.api.tenantId}/inspectors/${inspectorId}/status/deactivate`, undefined, { deactivationDate: c, certificationStandardId: certificationStandardId, basicActivityGroupId: basicActivityGroupId })
            , "Could not deactivate inspector", {})

    public getExcel = (filters: FilterWithValue[], sorting: SortCriterionWithValue[]) => {
        const queryParams = combineQueryParams(mapFiltersToQueryParamsObject(filters), mapSortingToQueryParamObject(sorting))
        return this.api.onUnexpected(
            this.api.execute<BlobResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/inspectors/export`, queryParams, undefined, ExportExcelTimeout, undefined, undefined, true),
            "Could not export given search to Excel",
            null)
    }

    public getStatistics = (certificationStandardId: number, year: number) =>
        this.api.onUnexpected(
            this.api.execute<Statistics>(200, 'GET', `api/tenants/${this.api.tenantId}/inspectors/statistics`, { year: year, certificationStandardId: certificationStandardId }),
            "Could not load statistics", null)

    public getStatisticsExcel = (certificationStandardId: number, year: number) =>
        this.api.onUnexpected(
            this.api.execute<BlobResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/inspectors/statistics/export`, { year: year, certificationStandardId: certificationStandardId }, undefined, ExportExcelTimeout, undefined, undefined, true),
            "Could not export statistics to Excel",
            null)

    public getInspectionCandidates = (customerId: number, date?: Date, standards?: number[], regionUnits?: number[]) =>
        this.api.onUnexpected(
            this.api.execute<CandidateInspector[]>(200, 'GET', `api/tenants/${this.api.tenantId}/inspectors/inspection-candidates`, { customerId: customerId, date: date ? toISOStringWIthTimezone(date) : undefined, standards: standards, regionUnits: regionUnits }),
            "Could not load candidates",
            [] as CandidateInspector[])
}