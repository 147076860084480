import { ApiClient } from "./ApiClient"

export type OrganicCertificationCategoryView =
    {
        organicCertificationCategoryId: number
        name: string
    }

export class OrganicCertificationCategoriesApi {
    constructor(private readonly api: ApiClient) { }

    public get = () =>
        this.api.onUnexpected(
            this.api.execute<OrganicCertificationCategoryView[]>(200, 'GET', `api/organic-certification-categories`),
            "Could not load Organic certification categories",
            []
        )
}
