import { Box, Typography } from "@mui/material"
import React from "react"
import { ApiResponse } from "../api/ApiClient"
import { ForbiddenPage } from "../components/ForbiddenPage"
import { SnackbarContextType } from "../components/core/Snackbar/SnackbarContext"
import { CorrelationIdLabel } from "./CorrelationIdLabel"
import { caseNever } from "./never"

export type ErrorHandler = <U, V>(promise: Promise<ApiResponse<U, "unexpected">>, message: string, onErrorValue?: V | undefined, onSuccessNotify?: boolean, onNotFoundSupressNotifications?: boolean, onBadRequestSupressNotifications?: boolean) => Promise<U | V>

export const onUnexpected = (context: SnackbarContextType) => async <U, V>(promise: Promise<ApiResponse<U>>, message: string, onErrorValue?: V, onSuccessNotify?: boolean, onNotFoundSupressNotifications?: boolean, onBadRequestSupressNotifications?: boolean): Promise<U | V> => {
    const result = await promise
    switch (result.kind) {
        case 'success':
            if (onSuccessNotify)
                context.successNotification("Success", <CorrelationIdLabel correlationId={result.correlationId} />, undefined, { autoHide: true })
            return result.data
        case 'unexpected':
            const details = <>
                <Typography variant="body1"><b>{result.message}</b></Typography>
                <Box marginTop={2}>
                    <CorrelationIdLabel correlationId={result.correlationId} status={result.status} />
                </Box>
                <pre>{result.trace}</pre>
            </>
            switch (result.status) {
                case 400: if (!onBadRequestSupressNotifications) context.warningNotification(message, <>
                    {!result.failures ? `Empty failures list.` : <ul>{Object.values(result.failures).flatMap(x => x).flatMap(x => x.split("\n")).filter(x => x).map(x => <li key={x}>{x}</li >)}</ul >}
                    <CorrelationIdLabel correlationId={result.correlationId} status={result.status} />
                </>); break
                case 403: context.warningNotification(message,
                    <Box>
                        {result.message
                            ? <Typography variant="body1"><b>{result.message}</b></Typography>
                            : <ForbiddenPage />}
                        <Box marginTop={2}>
                            <CorrelationIdLabel correlationId={result.correlationId} status={result.status} />
                        </Box>
                    </Box >); break
                case 404: if (!onNotFoundSupressNotifications) context.errorNotification("Δεν βρέθηκε η εγγραφή", details); break
                case 204: context.diagnosticNotification("Δεν αποθηκεύτηκε κάποια αλλαγή", details); break
                case 501: context.errorNotification("Η ενέργεια δεν έχει υλοποιηθεί ή δεν υποστηρίζεται.", details); break
                default: context.errorNotification(message, details); break
            }
            if (onErrorValue !== undefined)
                return onErrorValue
            else
                throw new Error(message)
        default: caseNever(result)
    }
}

export const onUnexpectedIgnore: ErrorHandler = async (promise, message, onErrorValue) => {
    const result = await promise
    switch (result.kind) {
        case 'success': return result.data
        case 'unexpected':
            if (onErrorValue !== undefined)
                return onErrorValue
            else
                throw new Error(message)
        default: caseNever(result)
    }
}