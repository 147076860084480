import { ApiClient } from "./ApiClient"

export type JobSpecializationModel =
    {
        jobSpecializationId: number
    }

export type JobSpecializationView =
    {
        jobSpecializationId: number
        name: string
    }

export class JobSpecializationsApi {
    constructor(private readonly api: ApiClient) { }

    public get = () =>
        this.api.onUnexpected(
            this.api.execute<JobSpecializationView[]>(200, 'GET', `api/job-specializations`),
            "Could not load job specializations",
            []
        )
}