import { idOrEmptyRoute } from "../utils/utils"
import { ApiClient, BlobResponse } from "./ApiClient"
import { IdResult } from "./Common"
import { deleteResource, getResource, putResource } from "./Search"
import { RiskAnalysisFactorSeverityId } from "./WellKnowIds"

export type BasicActivitySubcategory = {
    basicActivitySubcategoryId: number,
    name: string
}

export type CustomerRiskAnalysisEntry = {
    legacyRiskAnalysisEntryId: number,
    basicActivitySubcategoryId: number,
    createdUserId: number,
    modifiedUserId: number,
    dateCreated: Date,
    dateModified: Date,
    date: Date,
    applicationId?: number,
    ticketId?: number,
    outcome: RiskAnalysisOutcome
}

export type RiskAnalysisOutcome = {
    sampleScore: number,
    unannouncedInspectionScore: number,
    irregularInspectionScore: number,
}

export type EditLegacyRiskAnalysisEntryModel = {
    applicationId?: number
    ticketId?: number
    customerId?: number
    basicActivitySubcategoryId: number
} & Factors

export type Factors = {
    unitQuantity: RiskAnalysisFactorSeverityId
    likeHdb: RiskAnalysisFactorSeverityId
    newEntryInTheSystem: RiskAnalysisFactorSeverityId
    lastInspectionResults: RiskAnalysisFactorSeverityId
    complaints: RiskAnalysisFactorSeverityId
    writeTime: RiskAnalysisFactorSeverityId
    productsTypeAndValue: RiskAnalysisFactorSeverityId
    increasedProduction: RiskAnalysisFactorSeverityId
    amountProduction: RiskAnalysisFactorSeverityId
    replaceHazard: RiskAnalysisFactorSeverityId
    applicationOfExceptions: RiskAnalysisFactorSeverityId
    subcontractingActivity: RiskAnalysisFactorSeverityId
}

export enum RiskAnalysisVersion {
    V2023 = 1,
    V2024 = 2
}

export class LegacyRiskAnalysisApi {
    constructor(private readonly api: ApiClient) { }

    public getVersion = (legacyRiskAnalysisEntryId: number | undefined, applicationId: number | undefined, ticketId: number | undefined) =>
        getResource<null | { version: RiskAnalysisVersion }>(this.api, `api/tenants/${this.api.tenantId}/legacy-risk-analysis/version`, null, { queryParams: { legacyRiskAnalysisEntryId, applicationId, ticketId } })

    public getBasicActivitySubcategories = () =>
        getResource<BasicActivitySubcategory[]>(this.api, `api/tenants/${this.api.tenantId}/legacy-risk-analysis/basic-activity-subcategory`, [])

    public getCustomerLegacyRiskAnalysisEntries = (customerId: number | undefined, applicationId: number | undefined, ticketId: number | undefined) =>
        getResource<CustomerRiskAnalysisEntry[]>(this.api, `api/tenants/${this.api.tenantId}/legacy-risk-analysis`, [], { queryParams: { customerId, applicationId, ticketId } })

    public upsert = (legacyRiskAnalysisEntryId: number | undefined, e: EditLegacyRiskAnalysisEntryModel) =>
        putResource<EditLegacyRiskAnalysisEntryModel, IdResult | null>(this.api, `api/tenants/${this.api.tenantId}/legacy-risk-analysis${idOrEmptyRoute(legacyRiskAnalysisEntryId)}`, e)

    public getById = (legacyRiskAnalysisEntryId: number) =>
        getResource<EditLegacyRiskAnalysisEntryModel | null>(this.api, `api/tenants/${this.api.tenantId}/legacy-risk-analysis${idOrEmptyRoute(legacyRiskAnalysisEntryId)}`, null)

    public delete = (legacyRiskAnalysisEntryId: number) =>
        deleteResource(this.api, `api/tenants/${this.api.tenantId}/legacy-risk-analysis/${legacyRiskAnalysisEntryId}`)

    public getReport = (year: number) =>
        getResource<BlobResponse | null>(this.api, `api/tenants/${this.api.tenantId}/legacy-risk-analysis/report`, null, { blob: true, queryParams: { year } })

}
