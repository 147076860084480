import { ApiClient } from "./ApiClient"
import { IdResult } from "./Common"
import { getResource, putResource } from "./Search"

export type CustomerEntersoftAgreementImported = {
    entersoftCustomerAgreementId: string
    startDate?: Date
    endDate?: Date,
    annualCost?: number,
    agreementType?: string
    entersoftAgreementStatus?: string
}

export class EntersoftIntegrationApi {
    constructor(private readonly api: ApiClient) { }

    public startEntersoftParser = (file: File, sheetName: string) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('sheetName', sheetName)
        return putResource<FormData, IdResult>(this.api, `api/tenants/${this.api.tenantId}/entersoft-integration/agreements/parser`, formData, { timeout: this.api.maxTimeoutMs })
    }

    public startEntersoftExporter = (year: number, filteredTaxRegistrationNumbers?: string) =>
        putResource<{ year: number, filteredTaxRegistrationNumbers?: string }, IdResult>(this.api, `api/tenants/${this.api.tenantId}/entersoft-integration/agreements/exporter`, { year, filteredTaxRegistrationNumbers })


    public getCustomerImportedEntersoftAgreements = (customerId: number) =>
        getResource<CustomerEntersoftAgreementImported[]>(this.api, `api/tenants/${this.api.tenantId}/entersoft-integration/agreements/customers/${customerId}/imported`, [])
}