import { ApiClient } from "./ApiClient"
import { getResource } from "./Search"

export type Region =
    {
        regionId: number
        name: string
    }

export type RegionUnit =
    {
        regionUnitId: number
        name: string
    }

export type Municipality =
    {
        municipalityId: number
        name: string
    }

export type MunicipalityUnit =
    {
        municipalityUnitId: number
        name: string
    }

export type Community =
    {
        communityId: number
        name: string
        type: string
    }

export type DetailedCommunityView =
    {
        regionId: number
        regionUnitId: number
        municipalityId: number
        municipalityUnitId: number
        communityId: number
        regionName: string
        regionUnitName: string
        municipalityName: string
        municipalityUnitName: string
        communityName: string
        communityCode?: number
    }

export class RegionsApi {
    constructor(private readonly api: ApiClient) { }

    public getRegions = () =>
        this.api.onUnexpected(
            this.api.execute<Region[]>(200, 'GET', `api/tenants/${this.api.tenantId}/regions`),
            "Could not load regions",
            [] as Region[])

    public getAllRegionUnits = () =>
        this.api.onUnexpected(
            this.api.execute<RegionUnit[]>(200, 'GET', `api/tenants/${this.api.tenantId}/regions/region-units`),
            "Could not load region units",
            [] as RegionUnit[])

    public getRegionUnits = (regionId: number) =>
        this.api.onUnexpected(
            this.api.execute<RegionUnit[]>(200, 'GET', `api/tenants/${this.api.tenantId}/regions/${regionId}/region-units`),
            "Could not load region units",
            [] as RegionUnit[])

    public getMunicipalities = (regionUnitId: number) =>
        this.api.onUnexpected(
            this.api.execute<Municipality[]>(200, 'GET', `api/tenants/${this.api.tenantId}/regions/region-units/${regionUnitId}/municipalities`),
            "Could not load municipalities",
            [] as Municipality[])

    public getMunicipalityUnits = (municipalityId: number) =>
        this.api.onUnexpected(
            this.api.execute<MunicipalityUnit[]>(200, 'GET', `api/tenants/${this.api.tenantId}/regions/municipalities/${municipalityId}/municipality-units`),
            "Could not load municipality units",
            [] as MunicipalityUnit[])

    public getCommunities = (municipalityUnitId: number) =>
        this.api.onUnexpected(
            this.api.execute<Community[]>(200, 'GET', `api/tenants/${this.api.tenantId}/regions/municipality-units/${municipalityUnitId}/communities`),
            "Could not load communities",
            [] as Community[])

    public getCommunityReverseLookup = (communityId: number) =>
        this.api.onUnexpected(
            this.api.execute<DetailedCommunityView>(200, 'GET', `api/tenants/${this.api.tenantId}/regions/communities/${communityId}/reverse-lookup`),
            "Could not do a reverse lookup",
            null)

    public findCommunityIdFromCode = (communityCode: number) =>
        getResource<{ communityId?: number }>(this.api, `api/tenants/${this.api.tenantId}/regions/communities`, {}, { queryParams: { communityCode } })

    public getAllCommunities = () =>
        getResource<DetailedCommunityView[] | null>(this.api, `api/tenants/${this.api.tenantId}/regions/communities/all`, null)
}

