export enum Environment {
    Production = 0,
    Qa = 1,
    Dev = 2,
}

const ifEmptyDefault = (setting: string, defaultValue: string) => setting === "" || setting.startsWith("__INJECT_") ? defaultValue : setting

export const TENANT_ID = ifEmptyDefault("51374ca4-522b-4f1e-91f4-d62318559627", import.meta.env.VITE_BIO_APP_AAD_TENANT_ID!)
export const APIAPP_ID = ifEmptyDefault("02726da2-05ef-44f9-8b1e-a0d347bf1e2b", import.meta.env.VITE_BIO_APP_AAD_APIAPP_ID!)
export const WEBAPP_ID = ifEmptyDefault("9d5d8593-18f6-45b9-963f-0da9a988069f", import.meta.env.VITE_BIO_APP_AAD_WEBAPP_ID!)
export const API_URL = ifEmptyDefault("https://app.bio-hellas.gr", `${window.location.protocol}//${window.location.hostname}:${window.location.port}`)
export const VERSION = import.meta.env.VITE_BIO_APP_VERSION ?? "0.0.0"
export const COMMIT = import.meta.env.VITE_BIO_APP_COMMIT ?? "0000000"
export const BUILD_DATE = import.meta.env.VITE_BIO_APP_BUILD_DATE ?? "00000000"
const ENVIRONMENT_RAW = ifEmptyDefault("Production", import.meta.env.VITE_BIO_APP_ENVIRONMENT || "Production")

export const ENVIRONMENT = ENVIRONMENT_RAW === "Development" ? Environment.Dev : ENVIRONMENT_RAW === "Qa" ? Environment.Qa : Environment.Production
