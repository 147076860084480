import { ApiClient } from "./ApiClient"

export type EmployeeBenefitModelBase =
    {
        employeeBenefitId: number
    }

export type EmployeeBenefitView =
    {
        employeeBenefitId: number
        name: string
    }

export class EmployeeBenefitsApi {
    constructor(private readonly api: ApiClient) { }

    public get = () =>
        this.api.onUnexpected(
            this.api.execute<EmployeeBenefitView[]>(200, 'GET', `api/employee-benefits`),
            "Could not load employee benefits",
            []
        )
}